import classNames from 'classnames';
import React from 'react';
import ChevronLeft from 'assets/images/ChevronLeft.svg';
import ChevronRight from 'assets/images/ChevronRight.svg';
import type { Layout } from 'types';
import CopyClipboardIcon from './CopyClipboardIcon';
import styles from './styles.module.scss';

interface Props {
  displayName: string;
  layoutVariants: Layout[];
  layoutVariantIdx: number;
  onChangeLayout: (layout: Layout) => void;
}

const ActionPanel: React.FC<Props> = ({
  displayName,
  layoutVariants,
  layoutVariantIdx,
  onChangeLayout,
}) => {
  if (!layoutVariants.length) {
    return null;
  }

  const prev: Layout | undefined = layoutVariants[
    layoutVariantIdx === 0 ? (layoutVariants.length - 1) : layoutVariantIdx - 1
  ];
  const next: Layout | undefined = layoutVariants[
    layoutVariantIdx === (layoutVariants.length - 1) ? 0 : layoutVariantIdx + 1
  ];

  const handleArrowClick = (event: React.MouseEvent, layout: Layout): void => {
    event.stopPropagation();
    if (layoutVariants.length > 1) {
      onChangeLayout(layout);
    }
  };

  const assetNumber = layoutVariants[layoutVariantIdx]?.number;

  return (
    <div className={classNames(styles.actionPanel, 'shadow')} data-testid="simulation-section-action-panel">
      <div className="display-name bg-white fw-bold" style={{ minWidth: 150 }}>{displayName}
        {assetNumber &&
          <span className="fw-light d-flex align-items-center justify-content-between">
            <span className='pe-2'>{assetNumber}</span>
            <CopyClipboardIcon text={assetNumber} />
          </span>
        }
      </div>
      {layoutVariantIdx < 0 ? (
        <button
          className="btn btn-light"
          onClick={(event): void => {
            event.stopPropagation();
            onChangeLayout(layoutVariants[0]);
          }}
        >
          View Modular Content
        </button>
      ) : (
        <>
          <button
            onClick={prev && ((event): void => handleArrowClick(event, prev))}
            className="btn btn-light"
            type="button"
          >
            <ChevronLeft/>
          </button>
          <div className="bg-white d-flex align-items-center">
            <div>{`${(layoutVariantIdx + 1)}/${layoutVariants.length}`}</div>
          </div>
          <button
            onClick={next && ((event): void => handleArrowClick(event, next))}
            className="btn btn-light"
            type="button"
          >
            <ChevronRight/>
          </button>
        </>
      )}
    </div>
  );
};

export default ActionPanel;
