const STORAGE_KEY = 'persistentState';

export function clearStorageState(): void {
  sessionStorage.removeItem(STORAGE_KEY);
}

export function saveToLocalStorage<T>(state: T): void {
  const serialisedState = JSON.stringify(state);
  sessionStorage.setItem(STORAGE_KEY, serialisedState);
}

export function loadFromLocalStorage<T = unknown>(): T | undefined {
  const serialisedState = sessionStorage.getItem(STORAGE_KEY);
  if (serialisedState === null) {
    return undefined;
  }

  return JSON.parse(serialisedState) as T;
}
