import React from 'react';
import ReactDOM from 'react-dom';
import Modules from 'pages/Modules/Modules';
import styles from './ModulesModal.module.scss';

interface BackdropProps {
  onModalClose: () => void;
}

type ModalProps = {
  jobZeroId: string;
  moduleBundleId: string;
  onModalClose: () => void;
  onFormSubmit: () => void;
  isNoMoreToAdd: boolean;
};

const Backdrop: React.FC<BackdropProps> = ({ onModalClose }) => {
  return <div className={styles.backdrop} onClick={onModalClose} onKeyUp={(): void => undefined}/>;
};

const ModalOverlay: React.FC<ModalProps> = (props) => {
  const {
    jobZeroId,
    moduleBundleId,
    onModalClose,
    onFormSubmit,
    isNoMoreToAdd,
  } = props;

  return (
    <div className={styles.modal}>
      <div className={styles.modalBody}>
        <button
          type="button"
          className={`${styles.closeButton} btn-close`}
          aria-label="Close"
          onClick={onModalClose}
        />
        <Modules modal={{ jobZeroId, moduleBundleId, onFormSubmit, isNoMoreToAdd }} />
      </div>
    </div>
  );
};

const ModulesModal: React.FC<ModalProps> = (props) => {
  const {
    jobZeroId,
    moduleBundleId,
    onModalClose,
    onFormSubmit,
    isNoMoreToAdd,
  } = props;

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onModalClose={onModalClose} />,
        document.getElementById('backdrop-root') as HTMLDivElement,
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          jobZeroId={jobZeroId}
          moduleBundleId={moduleBundleId}
          onModalClose={onModalClose}
          onFormSubmit={onFormSubmit}
          isNoMoreToAdd={isNoMoreToAdd}
        />,
        document.getElementById('modal-root') as HTMLDivElement,
      )}
    </>
  );
};

export default ModulesModal;
