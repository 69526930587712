import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';
import { GetRecipeRequestPayload } from './recipe';
import type { RootState } from './index';

interface RecipeState {
  success: boolean;
  status: SAGA_STATUS;
  error: string;
}

const initialState: RecipeState = {
  success: false,
  status: SAGA_STATUS.IDLE,
  error: '',
};

export const recipeSlice = createSlice({
  name: 'recipe',
  initialState,
  reducers: {
    getRecipeStart: (state) => {
      state.status = SAGA_STATUS.PENDING;
      state.error = '';
    },
    getRecipeSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
      state.status = SAGA_STATUS.IDLE;
    },
    getRecipeError: (state, action: PayloadAction<string | undefined>) => {
      state.status = SAGA_STATUS.IDLE;
      state.error = action.payload ?? 'Unknown recipe error';
    },
    resetRecipe: () => initialState,
  },
});

export const getRecipeRequest = createAction<GetRecipeRequestPayload>('getRecipeRequest');

export const selectRecipeDataLoadingStatus = (state: RootState): boolean =>
  state.recipe.status === SAGA_STATUS.PENDING;

export const selectRecipeData = (state: RootState): boolean =>
  state.recipe.success;

export const selectRecipeError = (state: RootState): string =>
  state.recipe.error;

export const {
  getRecipeStart,
  getRecipeSuccess,
  getRecipeError,
  resetRecipe,
} = recipeSlice.actions;

export default recipeSlice.reducer;
