import { isAxiosError, AxiosError } from 'axios';

export type ApiError = AxiosError<{ message?: string }>;

export function checkAndFormatAxiosError<T>(error: T): Error | T {
  if (!isAxiosError(error)) {
    return error;
  }

  const { response } = error as ApiError;

  return new Error(response?.data.message ?? 'Unknnown API Error');
}
