import React from 'react';

interface SimulationErrorPageProps {
  text: string;
  children: React.ReactNode;
}

const SimulationErrorPage: React.FC<SimulationErrorPageProps> = ({ text, children }) => (
  <div className="h-100 d-flex flex-column flex-grow-1 align-items-center justify-content-center">
    { children }
    <div className="mt-3 text-center text-secondary">
      <p>{ text }</p>
    </div>
  </div>
);

export default SimulationErrorPage;
