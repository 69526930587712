import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import Section from 'components/Section';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectMergedSimulationCombinations, setActiveScreen } from 'store/simulationSlice';

import type { TemplateSection, Layout, ScreenToView } from 'types';
import styles from './styles.module.scss';

interface ScreenProps {
  sections: TemplateSection[];
  screenId: string;
  scrollContainer: React.RefObject<HTMLDivElement>;
  onChangeLayoutVariant: (section: TemplateSection, layout: Layout | null, screen: ScreenToView) => void;
}

const SECTION_WIDTH = 800;
const INITIAL_LOAD_TIME_TRESHHOLD = 2000;
const INTERSECTION_RATIO_MORE_THAN_HALF_SCREEN = 0.5;

const Screen = forwardRef<HTMLDivElement, ScreenProps>(({
  screenId,
  sections,
  scrollContainer,
  onChangeLayoutVariant,
}, ref) => {
  const dispatch = useAppDispatch();

  const mergedSimulationCombinations = useAppSelector(selectMergedSimulationCombinations);

  const screenRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => screenRef.current!, []);

  useEffect(
    () => {
      const intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          // on initial load we should take entry with the big intersection ratio as this is our visible screen
          if (
            entry.time < INITIAL_LOAD_TIME_TRESHHOLD
            && entry.intersectionRatio < INTERSECTION_RATIO_MORE_THAN_HALF_SCREEN
          ) {
            return;
          }
          // during scroll page currently intersecting is the one we need
          if (entry.isIntersecting) {
            dispatch(setActiveScreen(screenId));
          }
        });
      }, {
        rootMargin: '-35% 0px -60% 0px',
      });

      if (screenRef.current) {
        intersectionObserver.observe(screenRef.current);
      }

      return (): void => {
        if (screenRef.current) {
          intersectionObserver.unobserve(screenRef.current);
        }
      };
    }, [screenRef],
  );

  return (
    <div className={styles.screen} ref={screenRef}>
      {sections.map((section) => {
        const { id, displayName, layouts } = section;
        const matchedScreens = mergedSimulationCombinations.filter(template => template.sectionIds.includes(id));
        const layoutVariants = matchedScreens.length
          ? matchedScreens.reduce((result, matchedScreen) => {
            result = [...result, ...matchedScreen.layouts];

            return result;
          }, [] as Layout[])
          : [];

        return (
          <Section
            key={id}
            id={id}
            displayName={displayName}
            layouts={layouts}
            layoutVariants={layoutVariants}
            width={SECTION_WIDTH}
            scrollContainer={scrollContainer}
            screenId={matchedScreens.length ? matchedScreens[0].id : ''}
            matchedScreens={matchedScreens}
            onCnahgeLayoutVariant={onChangeLayoutVariant.bind(null, section)}
          />
        );
      })}

      <div className={styles.backdrop}>
        <div style={{ width: SECTION_WIDTH }} />
      </div>
    </div>
  );
});

Screen.displayName = 'Screen';

export default Screen;
