import React from 'react';
import type { ModuleTemplate } from 'types';
import { getSimulationChannelThumbnail } from 'utils';
import styles from './TemplateCard.module.scss';

interface TemplateCardProps {
  template: ModuleTemplate;
  redirectToChannel: (template: ModuleTemplate) => void;
}

const TemplateCard: React.FC<TemplateCardProps> = ({ template, redirectToChannel }) => {
  const { resourceType } = template;
  const Thumbnail = getSimulationChannelThumbnail(resourceType);

  return (
    <div
      onClick={redirectToChannel.bind(this, template)}
      onKeyUp={(): void => undefined}
      className={styles.TemplateCard}
    >
      <div className={styles.thumbnail}>
        <Thumbnail />
      </div>
      <div className={styles.details}>{template.resourceType}</div>
    </div>
  );
};

export default TemplateCard;
