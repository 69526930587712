import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ProjectType, SAGA_STATUS } from 'const';
import type {
  ModuleBundleDocument,
  ModuleBundleActionPayload,
  Screen,
  Layout,
} from 'types';
import { mergeSimulationCombinations, applyLayoutStatus } from 'utils';
import {
  SimulationState,
  SimulationAddLayoutOnSectionPayload,
  SimulationRemoveLayoutOnSectionPayload,
  SimulationScrollSide,
} from './simulation';
import type { RootState } from './index';

export const initialState: SimulationState = {
  searchString: '',
  moduleBundleData: {
    moduleBundle: {
      id: '',
      name: '',
      resourceType: '',
      projectType: '' as ProjectType,
      veevaUrl: '',
      screens: [] as Screen[],
    },
    status: SAGA_STATUS.IDLE,
    error: '',
  },
  templateData: {
    template: {
      id: '',
      name: '',
      resourceType: '',
      projectType: '' as ProjectType,
      veevaUrl: '',
      screens: [] as Screen[],
    },
    status: SAGA_STATUS.IDLE,
    error: '',
  },
  mergedSimulationCombinations: [],
  activeScreenId: '',
  activePanels: {},
  scrollPositions: {
    left: 0,
    right: 0,
  },
  templateReplacedLayouts: {},
};

export const simulationSlice = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    resetSimulationState: () => initialState,
    getModuleBundleTemplateDataStart: (state) => {
      state.moduleBundleData.status = SAGA_STATUS.PENDING;
      state.moduleBundleData.error = '';
    },
    getModuleBundleTemplateDataSuccess: (
      state,
      action,
    ) => {
      state.moduleBundleData.status = SAGA_STATUS.IDLE;
      const {
        error,
        id = '',
        name = '',
        veevaUrl = '',
      } = action.payload;
      if (error) {
        state.moduleBundleData.error = error;
        state.moduleBundleData.moduleBundle = {
          ...initialState.moduleBundleData.moduleBundle,
          id,
          name,
          veevaUrl,
        };
      } else {
        state.moduleBundleData.moduleBundle = action.payload;
      }
    },
    getModuleBundleTemplateDataError: (
      state,
      action,
    ) => {
      state.moduleBundleData.status = SAGA_STATUS.IDLE;
      state.moduleBundleData.error = action.payload;
    },
    getTemplateDataStart: (state) => {
      state.templateData.status = SAGA_STATUS.PENDING;
      state.templateData.error = '';
    },
    getTemplateDataSuccess: (
      state,
      action,
    ) => {
      state.templateData.status = SAGA_STATUS.IDLE;
      state.templateData.template = action.payload;
    },
    getTemplateDataError: (
      state,
      action,
    ) => {
      state.templateData.status = SAGA_STATUS.IDLE;
      state.templateData.error = action.payload;
    },
    setMergedSimulationCombinations: (
      state,
      action,
    ) => {
      state.mergedSimulationCombinations = action.payload;
    },
    addLayoutOnSection: (
      state,
      action: PayloadAction<SimulationAddLayoutOnSectionPayload>,
    ) => {
      const { entityId, layout, shouldRemoveLayout = false } = action.payload;

      for (const templeateScreen of state.templateData.template.screens) {
        const templateSection = templeateScreen.sections.find(({ id }) => id === entityId);

        if (templateSection) {
          const stateScreens = state.mergedSimulationCombinations.filter(
            simulationCombination => simulationCombination.sectionIds.includes(entityId),
          );
          const stateScreensLayouts = _(stateScreens).flatMap<Layout>('layouts').value();

          stateScreensLayouts.forEach((stateScreensLayout: Layout) => {
            stateScreensLayout.active = false;
          });

          const indexOfSection = templateSection.layouts.findIndex((({ id }) => id === layout.id));

          if (indexOfSection === -1) {
            if (!state.templateReplacedLayouts[entityId]) {
              state.templateReplacedLayouts[entityId] = [...templateSection.layouts];
            }
            templateSection.layouts = [layout];
            const currentLayout = stateScreensLayouts.find(screenLayout => screenLayout.id === layout.id);
            if (currentLayout) {
              currentLayout.active = true;
            }
          } else if (shouldRemoveLayout) {
            templateSection.layouts = [...state.templateReplacedLayouts[entityId]];
            delete state.templateReplacedLayouts[entityId];
          }
        }
      }

      return state;
    },
    removeLayoutOnSection: (
      state,
      action: PayloadAction<SimulationRemoveLayoutOnSectionPayload>,
    ) => {
      const { entityId } = action.payload;

      for (const screen of state.templateData.template.screens) {
        const templateSection = screen.sections.find(({ id }) => id === entityId);

        if (templateSection) {
          const stateScreens = state.mergedSimulationCombinations.filter(
            simulationCombination => simulationCombination.sectionIds.includes(entityId),
          );

          stateScreens.forEach(stateScreen => stateScreen.layouts = applyLayoutStatus(stateScreen.layouts, false));
          if (state.templateReplacedLayouts[entityId]) {
            templateSection.layouts = [...state.templateReplacedLayouts[entityId]];
            delete state.templateReplacedLayouts[entityId];
          }
        }
      }

      return state;
    },
    removeAllLayouts: (
      state,
    ) => {
      for (const screen of state.templateData.template.screens) {
        screen.sections.forEach((section) => {
          const replacedLayouts = state.templateReplacedLayouts[section.id];

          if (replacedLayouts) {
            section.layouts = [...replacedLayouts];
          }
        });
      }
      state.mergedSimulationCombinations.forEach((simulationCombination) => {
        simulationCombination.layouts = applyLayoutStatus(simulationCombination.layouts, false);
      });
      state.templateReplacedLayouts = {};

      return state;
    },
    setActiveScreen: (
      state,
      action,
    ) => {
      state.activeScreenId = action.payload;
    },
    setSearchString: (
      state,
      action,
    ) => {
      state.searchString = action.payload;
    },
    toggleActivePanels: (
      state,
      action: { payload: { screenId: string; activeState?: boolean } },
    ) => {
      const { screenId, activeState } = action.payload;

      const currentState = Boolean(state.activePanels[screenId]);
      state.activePanels[screenId] = typeof activeState === 'boolean' ? activeState : !currentState;
    },
    setScrollPosition: (state, action: { payload: { side: SimulationScrollSide; value: number } }) => {
      const { side, value } = action.payload;
      state.scrollPositions[side] = value;
    },
  },
});

export const selectActivePanels = ({ simulation }: RootState): Record<string, boolean> => simulation.activePanels;
export const selectSimulationDataLoadingStatus = ({ simulation }: RootState): boolean =>
  simulation.moduleBundleData.status === SAGA_STATUS.PENDING
  || simulation.templateData.status === SAGA_STATUS.PENDING;
export const areSearchParamsSame = (searchString: string) => ({ simulation }: RootState): boolean =>
  simulation.searchString === searchString;
export const selectErrorTemplateData = ({ simulation }: RootState): string => simulation.templateData.error;
export const selectErrorModuleBundleData = ({ simulation }: RootState): string => simulation.moduleBundleData.error;
export const getModuleBundleTemplateDataRequest = createAction<ModuleBundleActionPayload>(
  'getModuleBundleTemplateDataRequest',
);
export const getTemplateDataRequest = createAction<ModuleBundleActionPayload>('getTemplateDataRequest');
export const selectModuleBundle = (
  state: RootState,
): ModuleBundleDocument => state.simulation.moduleBundleData.moduleBundle;
export const selectTemplate = (state: RootState): ModuleBundleDocument => state.simulation.templateData.template;
export const selectTemplateHasLayouts = (state: RootState): boolean => state.simulation.templateData?.template?.screens
  .some(({ sections }) => sections.some(({ layouts }) => layouts.length));
export const selectMergedSimulationCombinations = (state: RootState):
ReturnType<typeof mergeSimulationCombinations> => !selectSimulationDataLoadingStatus(state)
  ? state.simulation.mergedSimulationCombinations : [];
export const selectActiveScreen = (state: RootState): string => state.simulation.activeScreenId;
export const selectScreens = (state: RootState): Screen[] => state.simulation.templateData.template.screens;
export const selectScrollPositions = (
  state: RootState,
): Record<SimulationScrollSide, number> => state.simulation.scrollPositions;
export const selectTemplateReplacedLayouts = (
  state: RootState,
): Record<string, Layout[]> => state.simulation.templateReplacedLayouts;

export const {
  getModuleBundleTemplateDataStart,
  getModuleBundleTemplateDataSuccess,
  getModuleBundleTemplateDataError,
  getTemplateDataStart,
  getTemplateDataSuccess,
  getTemplateDataError,
  setMergedSimulationCombinations,
  addLayoutOnSection,
  removeLayoutOnSection,
  removeAllLayouts,
  setActiveScreen,
  setSearchString,
  resetSimulationState,
  toggleActivePanels,
  setScrollPosition,
} = simulationSlice.actions;

export default simulationSlice.reducer;
