import React from 'react';
import { CopyClipboardSvg } from 'assets/images/CopyClipboardSvg';
import CopyClipboardIcon from 'components/Section/CopyClipboardIcon';
import styles from './ClipboardText.module.scss';

type Props = {
  text: string;
};

const ClipboardText: React.FC<Props> = ({ text }) => {
  return (
    <div className={styles.ClipboardTextWrapper}>
      <span className={styles.ClipboardText}>{text}</span>
      <div className={styles.ClipboardTextButton}>
        <CopyClipboardIcon
          text={text}
          Icon={CopyClipboardSvg}
        />
      </div>
    </div>
  );
};

export default ClipboardText;
