import { useState } from 'react';
import {
  getResourceTypeFilterDataRequest,
  selectSelectedFilters,
  setSelectedFilters,
} from 'store/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getJobZeroRequest, reset } from 'store/jobZeroSlice';
import { getSessionId } from 'utils';

type UseFiltersReturnType = {
  country: string;
  productFamily: string;
  resourceType: string;
  deliveryMethod: string;
  deploymentSupplier: string;
  isButtonDisabled: boolean;
  onCountryChangeHandler: (value: string) => void;
  onProductFamilyChangeHandler: (value: string) => void;
  onResourceTypeChangeHandler: (value: string) => void;
  onDeliveryMethodChangeHandler: (value: string) => void;
  onDeploymentSupplierChangeHandler: (value: string) => void;
  onFormSubmitHandler: (evt: React.FormEvent) => void;
};

export const useFilters = (
  onJobZeroRequestSubmitted: (isSubmitted: boolean) => void,
): UseFiltersReturnType => {
  const dispatch = useAppDispatch();
  const sessionId = getSessionId();
  const selectedFilters = useAppSelector(selectSelectedFilters);
  const [country, setCountry] = useState(selectedFilters.selectedCountry);
  const [productFamily, setProductFamily] = useState(
    selectedFilters.selectedProductFamily,
  );
  const [resourceType, setResourceType] = useState(
    selectedFilters.selectedResourceType,
  );
  const [deliveryMethod, setDeliveryMethod] = useState(
    selectedFilters.selectedDeliveryMethod,
  );
  const [deploymentSupplier, setDeploymentSupplier] = useState(
    selectedFilters.selectedDeploymentSupplier,
  );

  const onCountryChangeHandler = (value: string): void => {
    setCountry(value);
    dispatch(getResourceTypeFilterDataRequest(value));
  };

  const onProductFamilyChangeHandler = (value: string): void => {
    setProductFamily(value);
  };

  const onResourceTypeChangeHandler = (value: string): void => {
    setResourceType(value);
  };

  const onDeliveryMethodChangeHandler = (value: string): void => {
    setDeliveryMethod(value);
  };

  const onDeploymentSupplierChangeHandler = (value: string): void => {
    setDeploymentSupplier(value);
  };

  const onFormSubmitHandler = (event: React.FormEvent): void => {
    event.preventDefault();

    dispatch(reset());

    dispatch(
      setSelectedFilters({
        selectedCountry: country,
        selectedProductFamily: productFamily,
        selectedResourceType: resourceType,
        selectedDeliveryMethod: deliveryMethod,
        selectedDeploymentSupplier: deploymentSupplier,
      }),
    );

    dispatch(
      getJobZeroRequest({
        sessionId,
        productFamily,
        country,
        resourceType,
        deliveryMethod: deliveryMethod || '',
        deploymentSupplier: deploymentSupplier || '',
        onlyFirstPage: true,
      }),
    );

    onJobZeroRequestSubmitted(true);
  };

  return {
    country,
    productFamily,
    resourceType,
    deliveryMethod,
    deploymentSupplier,
    isButtonDisabled: !country || !productFamily || !resourceType,
    onCountryChangeHandler,
    onProductFamilyChangeHandler,
    onResourceTypeChangeHandler,
    onDeliveryMethodChangeHandler,
    onDeploymentSupplierChangeHandler,
    onFormSubmitHandler,
  };
};
