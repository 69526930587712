import React from 'react';

export const CopyClipboardSvg = () => (
  <svg width="14" height="16" viewBox="0 0 12 13" fill="none">
    <g id="Icon / CopyOutlined">
      <path id="Vector" d="M9.75 1.25H3.46875C3.41719 1.25 3.375 1.29219 3.375 1.34375V2C3.375 2.05156 3.41719 2.09375
        3.468752.09375H9.28125V10.1562C9.28125 10.2078 9.32344 10.25 9.375 10.25H10.0312C10.0828 10.25 10.125 10.2078
        10.125 10.1562V1.625C10.125 1.41758 9.95742 1.25 9.75 1.25ZM8.25 2.75H2.25C2.04258 2.75 1.875 2.91758 1.875
        3.125V9.34414C1.875 9.44375 1.91484 9.53867 1.98516 9.60898L4.01602 11.6398C4.0418 11.6656 4.07109 11.6867
        4.10273 11.7043V11.7266H4.15195C4.19297 11.7418 4.23633 11.75 4.28086 11.75H8.25C8.45742 11.75 8.625
        11.5824 8.625 11.375V3.125C8.625 2.91758 8.45742 2.75 8.25 2.75ZM4.10156 10.5336L3.09258
        9.52344H4.10156V10.5336ZM7.78125 10.9062H4.85156V9.24219C4.85156 8.9832 4.6418 8.77344 4.38281
        8.77344H2.71875V3.59375H7.78125V10.9062Z"
      fill="#00504A"/>
    </g>
  </svg>
);
