import React from 'react';
import type { Layout } from 'types';
import CopyClipboardIcon from './CopyClipboardIcon';

interface Props {
  layout: Layout;
}

const AssetIdField: React.FC<Props> = ({ layout }) => {
  const assetNumber = layout?.number;

  return (
    <div
      className="d-flex align-items-center justify-content-between text-nowrap fw-light"
      onClick={(event):void => event.stopPropagation()}
    >
      <small className='me-2'>{assetNumber}</small>
      <CopyClipboardIcon text={assetNumber} />
    </div>
  );
};

export default AssetIdField;
