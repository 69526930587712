import { OAUTH2_URL } from 'api/constants';

const stage = process.env.REACT_APP_STAGE ?? 'dev';

export const authenticate = (): void => {
  const redirect =
    window.location.host === 'localhost:3000'
      ? window.location.origin.replace('localhost', '127.0.0.1')
      : window.location.origin;

  const searchParams = new URLSearchParams({
    redirect_uri: redirect,
    response_type: 'code',
    client_id: 'PERMUTATIONSBUILDERUI',
    state: 'wnv1QYLnvidky4u6XbPVaXb5nPlZPMII5z32cMatBfg',
    code_challenge: 'SCSQXXAME6RE9Q3UOW0LITXYWABR8RBKKM2I39PT333',
    code_challenge_method: 'plain',
  });

  window.location.assign(
    `${OAUTH2_URL[stage]}?${searchParams.toString()}`,
  );
};
