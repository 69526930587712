import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';
import type {
  JobZeroActionPayload,
  JobZeroDocument,
  JobZeroDocumentData,
} from 'types';
import type { RootState } from './index';

interface JobZeroState {
  jobZeroDocuments: JobZeroDocument[];
  nextPage: string | undefined;
  lastSelectedDocumentId: string;
  status: SAGA_STATUS;
  error: string;
}

export const initialState: JobZeroState = {
  jobZeroDocuments: [],
  nextPage: '',
  lastSelectedDocumentId: '',
  status: SAGA_STATUS.IDLE,
  error: '',
};

export const jobZeroSlice = createSlice({
  name: 'jobZero',
  initialState,
  reducers: {
    getJobZeroStart: (state) => {
      state.status = SAGA_STATUS.PENDING;
      state.error = '';
    },
    getJobZeroSuccess: (state, action: PayloadAction<JobZeroDocumentData>) => {
      const { data, nextPage } = action.payload;
      state.jobZeroDocuments = [...state.jobZeroDocuments, ...data];
      state.nextPage = nextPage;
      state.status = SAGA_STATUS.IDLE;
    },
    getJobZeroError: (state, action: PayloadAction<string>) => {
      state.status = SAGA_STATUS.IDLE;
      state.error = action.payload;
    },
    setLastSelectedDocumentId: (state, action: PayloadAction<string>) => {
      state.lastSelectedDocumentId = action.payload;
    },
    setNextPage: (state, action: PayloadAction<string>) => {
      state.nextPage = action.payload;
    },
    reset: () => initialState,
  },
});

export const getJobZeroRequest =
  createAction<JobZeroActionPayload>('getJobZeroRequest');

export const selectJobZeroData = (state: RootState): JobZeroDocument[] =>
  state.jobZero.jobZeroDocuments;

export const selectJobZeroDataLoadingStatus = (state: RootState): boolean =>
  state.jobZero.status === SAGA_STATUS.PENDING;

export const selectJobZeroDataNextPage = (state: RootState): string | undefined =>
  state.jobZero.nextPage;

export const selectLastSelectedDocumentId = (state: RootState): string =>
  state.jobZero.lastSelectedDocumentId;

export const {
  getJobZeroStart,
  getJobZeroSuccess,
  getJobZeroError,
  reset,
  setLastSelectedDocumentId,
  setNextPage,
} = jobZeroSlice.actions;

export default jobZeroSlice.reducer;
