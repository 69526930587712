import _ from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NoModuleBundle from 'assets/images/NoModuleBundle';
import LoadingSpinner from 'components/LoadingSpinner';
import NavBar from 'components/NavBar/NavBar';
import TemplateCard from 'components/TemplateCard';
import {
  selectModuleBundle,
  getModuleBundleChannelsDataRequest,
  selectModuleBundleDataLoadingStatus,
} from 'store/channelsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import type { ModuleTemplate } from 'types';
import { sortModuleBundlesTemplatesByResourceType } from 'utils';
import { makeQuery } from 'utils/selectChannelQuery';
import Header from './components/Header';
import styles from './styles.module.scss';

export const spinnerTestId = 'spinnerTestId';

const Channels: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const documentId = Number(searchParams.get('documentId') ?? '');
  const majorVersion = Number(searchParams.get('MajorVersion') ?? '');
  const minorVersion = Number(searchParams.get('MinorVersion') ?? '');

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const moduleBundle = useAppSelector(selectModuleBundle);
  const isEmptyModuleBundle = !moduleBundle?.channels?.length;
  const isModuleBundleDataLoading = useAppSelector(selectModuleBundleDataLoadingStatus);
  const showChannels = _(moduleBundle?.channels)
    .uniqBy('resourceType')
    .sortBy(({ resourceType }) => sortModuleBundlesTemplatesByResourceType(resourceType))
    .value();

  useEffect(() => {
    dispatch(getModuleBundleChannelsDataRequest({ documentId, majorVersion, minorVersion }));
  }, [documentId, majorVersion, minorVersion]);

  const clickOnChannel = (template: ModuleTemplate): void => {
    navigate({
      pathname: 'combinations',
      search: makeQuery({ template, bundle: { documentId, majorVersion, minorVersion } }),
    });
  };

  // DCC-7572 - Select a channel automatically
  if (moduleBundle?.channels.length) {
    // setTimeout - redirect must be after render (React requirements)
    setTimeout(() => {
      clickOnChannel(moduleBundle.channels[0]);
    });
  }

  const renderModuleBundleTemplates = (): React.JSX.Element => {
    if (isModuleBundleDataLoading) {
      return (
        <div
          data-testid={spinnerTestId}
          className="d-flex h-100 flex-column align-items-center justify-content-center"
        >
          <LoadingSpinner />
        </div>
      );
    }

    if (isEmptyModuleBundle) {
      return (
        <>
          <Header moduleBundle={moduleBundle} majorVersion={majorVersion} minorVersion={minorVersion}/>
          <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
            <div className="mt-3 text-center">
              <NoModuleBundle />
              <h5 className="fw-bold">No Channel</h5>
              <p>No channel found for this module bundle</p>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <Header moduleBundle={moduleBundle} majorVersion={majorVersion} minorVersion={minorVersion}/>
        <div className={styles.TemplateContainer}>
          {showChannels.map(template => (
            <TemplateCard key={template.documentId} template={template} redirectToChannel={clickOnChannel} />
          ))}
        </div>
      </>
    );
  };

  return (
    <main className="d-flex flex-column align-items-stretch">
      <NavBar isModuleCombinationLabelShown={true} discardPositionFixed={true}/>
      <div className="px-3 px-lg-5 container-fluid d-flex flex-column flex-grow-1">
        {renderModuleBundleTemplates()}
      </div>
    </main>
  );
};

export default Channels;
