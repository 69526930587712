import axios from 'axios';
import { API, CONTENT_TYPE, BACKEND_URL } from 'api/constants';
import type {
  Country,
  DeliveryMethod,
  DeploymentSupplier,
  ProductFamily,
  ResourceType,
} from 'types';

export const apiInstance = axios.create({
  baseURL: BACKEND_URL,
});

export const getAuthorizationHeader = (sessionId: string): { [key: string]: string } => {
  return {
    Authorization: sessionId,
  };
};

export const getCountriesFromVeeva = async (
  sessionId: string,
): Promise<{ data: Country[] }> => {
  const body = {
    contentType: CONTENT_TYPE.COUNTRY,
  };

  return apiInstance.post(
    API.GET_DATA_FROM_VEEVA,
    body,
    {
      headers: {
        ...getAuthorizationHeader(sessionId),
      },
    },
  );
};

export const getProductFamiliesFromVeeva = async (
  sessionId: string,
): Promise<{ data: ProductFamily[] }> => {
  const body = {
    contentType: CONTENT_TYPE.PRODUCT_FAMILY,
  };

  return apiInstance.post(
    API.GET_DATA_FROM_VEEVA,
    body,
    {
      headers: {
        ...getAuthorizationHeader(sessionId),
      },
    },
  );
};

export const getDeliveryMethodsFromVeeva = async (
  sessionId: string,
): Promise<{ data: DeliveryMethod[] }> => {
  const body = {
    contentType: CONTENT_TYPE.DELIVERY_METHOD,
  };

  return apiInstance.post(
    API.GET_DATA_FROM_VEEVA,
    body,
    {
      headers: {
        ...getAuthorizationHeader(sessionId),
      },
    },
  );
};

export const getDeploymentSuppliersFromVeeva = async (
  sessionId: string,
): Promise<{ data: DeploymentSupplier[] }> => {
  const body = {
    contentType: CONTENT_TYPE.DEPLOYMENT_SUPPLIER,
  };

  return apiInstance.post(
    API.GET_DATA_FROM_VEEVA,
    body,
    {
      headers: {
        ...getAuthorizationHeader(sessionId),
      },
    },
  );
};

export const getResourceTypeByCountryFromVeeva = async (
  sessionId: string,
  countryId: string,
): Promise<{ data: ResourceType[] }> => {
  const body = {
    contentType: CONTENT_TYPE.RESOURCE_TYPE_BY_COUNTRY,
    id: countryId,
  };

  return apiInstance.post(
    API.GET_DATA_FROM_VEEVA,
    body,
    {
      headers: {
        ...getAuthorizationHeader(sessionId),
      },
    },
  );
};

export async function getRendition(
  sessionId: string,
  documentId: string,
  documentVersionId?: string,
): Promise<string> {
  const mode = document.location.pathname.startsWith('/simulation') ? 'simulator' : 'selector';

  const response = await fetch(
    `${BACKEND_URL}${API.GET_RENDITION}/${documentVersionId || documentId}?mode=${mode}`,
    {
      cache: 'default',
      method: 'get',
      headers: {
        ...getAuthorizationHeader(sessionId),
      },
    },
  );

  return URL.createObjectURL(await response.blob());
}
