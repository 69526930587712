import { replaceGroupsInBundle } from 'store/modules/helpers';
import { ModulesBundle, ModulesBundleModule } from 'store/modules/types';

export type ModulesBundleWithSelection = ModulesBundle & {
  selectedCount: number;
  availableCount: number;
};

export function validateSelection(bundles: ModulesBundleWithSelection[]): boolean {
  return bundles.some((bundle): boolean => {
    const moduleSelected = bundle.modules.some(module => module.selected);
    const groupSelected = bundle.groups.some(group => group.selected);

    return moduleSelected || groupSelected;
  });
}

function createBundleWithSelection(
  bundle: ModulesBundle,
): ModulesBundleWithSelection {
  const { modules, groups } = bundle;

  const availableModules = modules.filter(module => !module.disabled).length;
  const selectedModules = modules.filter(module => module.selected).length;
  const availableGroups = groups.filter(group => group).length;
  const selectedGroups = groups.filter(group => group.selected).length;

  return {
    ...bundle,
    modules: modules.map(module => ({ ...module })),
    groups: groups.map(group => ({ ...group })),
    selectedCount: selectedModules + selectedGroups,
    availableCount: availableModules + availableGroups,
  };
}

export function convertBundleWithSelectionToBundle(
  bundle: ModulesBundleWithSelection,
): ModulesBundle {
  const {
    modules,
    groups,
    selectedCount, // eslint-disable-line no-unused-vars
    availableCount, // eslint-disable-line no-unused-vars
    ...other
  } = bundle;

  return {
    ...other,
    modules: modules.map(module => ({ ...module })),
    groups: groups.map(group => ({ ...group })),
  };
}

function replaceBundle(
  bundles: ModulesBundleWithSelection[],
  bundleIndex: number,
  newBundle: ModulesBundle,
): ModulesBundleWithSelection[] {
  const newState = [...bundles];
  newState[bundleIndex] = createBundleWithSelection(newBundle);

  return newState;
}

function replaceModuleInBundle(
  bundle: ModulesBundleWithSelection,
  moduleIndex: number,
  module: ModulesBundleModule,
): ModulesBundleWithSelection {
  const modules = [ ...bundle.modules ];
  modules[moduleIndex] = module;

  return { ...bundle, modules };
}

export function updateBundles(
  current: ModulesBundleWithSelection[],
  updated: ModulesBundle[],
): ModulesBundleWithSelection[] {
  return updated.map((bundle) => {
    const currentModules = current[bundle.index]?.modules || bundle.modules;
    const modules = bundle.modules.map((module) => {
      return {
        ...module,
        selected: Boolean(currentModules?.[module.index]?.selected),
        disabled: Boolean(currentModules?.[module.index]?.disabled),
      };
    });

    const currentGroups = current[bundle.index]?.groups || bundle.groups;
    const groups = bundle.groups.map((group) => {
      return {
        ...group,
        selected: Boolean(currentGroups?.[group.index]?.selected),
      };
    });

    return createBundleWithSelection({
      ...bundle,
      modules,
      groups,
    });
  });
}

export function setModuleSelection(
  bundles: ModulesBundleWithSelection[],
  bundleIndex: number,
  moduleIndex: number,
  isSelected: boolean,
): ModulesBundleWithSelection[] {
  const module = bundles[bundleIndex].modules[moduleIndex];
  if (!module) {
    return bundles;
  }

  let updatedBundles = bundles;
  bundles.forEach((bundle) => {
    let updatedBundle = bundle;
    bundle.modules.forEach((item) => {
      if (item === module) {
        const newModule = {
          ...item,
          selected: isSelected,
          disabled: false,
        };
        updatedBundle = replaceModuleInBundle(updatedBundle, item.index, newModule);

        return;
      }
      if (item.id !== module.id || item.disabled === isSelected) {
        return;
      }
      const updatedModule = {
        ...item,
        disabled: isSelected || item.disabled,
        selected: isSelected ? false : item.selected,
      };
      updatedBundle = replaceModuleInBundle(updatedBundle, item.index, updatedModule);
    });
    if (updatedBundle !== bundle) {
      updatedBundles = replaceBundle(updatedBundles, bundle.index, updatedBundle);
    }
  });

  return updatedBundles;
}

export function setGroupSelection(
  bundles: ModulesBundleWithSelection[],
  bundleIndex: number,
  groupIndex: number,
  isSelected: boolean,
): ModulesBundleWithSelection[] {
  const group = bundles[bundleIndex]?.groups[groupIndex];
  if (!group || group.selected === isSelected) {
    return bundles;
  }

  const updatedGroups = bundles[bundleIndex].groups.map(i => i);
  updatedGroups[groupIndex] = {
    ...group,
    selected: isSelected,
  };

  return replaceBundle(
    bundles,
    bundleIndex,
    replaceGroupsInBundle(bundles[bundleIndex], updatedGroups),
  );
}

export function isEntireBundleSelected(
  bundle: ModulesBundleWithSelection | undefined,
): boolean {
  if (!bundle) {
    return false;
  }
  const { modules, groups } = bundle;
  const allModulesSelected = modules.filter(module => !module.disabled).every(module => module.selected);
  const allGroupsSelected = groups.every(group => group.selected);

  return allModulesSelected && allGroupsSelected;
}

export function setEntireBundleSelection(
  bundles: ModulesBundleWithSelection[],
  bundleIndex: number,
  isSelected: boolean,
): ModulesBundleWithSelection[] {
  const bundle = bundles[bundleIndex];
  if (!bundle) {
    return bundles;
  }

  let updatedState = bundle.modules.reduce(
    (acc, module) => acc[bundleIndex].modules[module.index].disabled
      ? acc
      : setModuleSelection(acc, bundleIndex, module.index, isSelected),
    bundles,
  );

  updatedState = bundle.groups.reduce(
    (acc, group) => setGroupSelection(acc, bundleIndex, group.index, isSelected),
    updatedState,
  );

  return updatedState;
}
