import classNames from 'classnames';
import React, { useState } from 'react';
import CheckIcon from 'assets/images/Check.svg';
import CopyIcon from 'assets/images/Copy.svg';
import styles from './styles.module.scss';

interface Props {
  text: string;
  Icon?: React.FC;
}

const IconClipboard: React.FC<React.FC | undefined> = Icon => Icon ? <Icon /> : <CopyIcon className='h-100'/>;

const CopyClipboardIcon: React.FC<Props> = ({ text, Icon }) => {
  const [clicked, setClicked] = useState(false);

  const copyClipboardClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    void navigator.clipboard.writeText(text);
    setClicked(true);
    setTimeout(()=>setClicked(false), 2000);
  };

  return (
    <button
      className={classNames({ 'py-1': !Icon }, [styles.buttonBg, 'btn btn-sm rounded-0'])}
      onClick={copyClipboardClick}
      disabled={clicked}
    >
      {clicked ? <CheckIcon className='h-100'/>
      : IconClipboard(Icon)}
    </button>
  );
};

export default CopyClipboardIcon;
