import React from 'react';
import ArrowLeft from 'assets/images/ArrowLeft';
import ArrowRight from 'assets/images/ArrowRight';

type Props = {
  onPrev: (() => void) | undefined;
  onNext: (() => void) | undefined;
};

const BundlesNavigation: React.FC<Props> = (props) => {
  const { onPrev, onNext } = props;

  return (
    <div className="d-flex justify-content-start">
      <button
        className="me-3 btn btn-outline-primary custom-button"
        onClick={onPrev}
        type="button"
        disabled={!onPrev}
      >
        <ArrowLeft />
        <span className="ms-2">Previous Module Type</span>
      </button>
      <button
        className="btn btn-outline-primary custom-button"
        onClick={onNext}
        type="button"
        disabled={!onNext}
      >
        <span className="me-2">Next Module Type</span>
        <ArrowRight />
      </button>
    </div>
  );
};

export default BundlesNavigation;
