import {
  all, put, call, select, takeLatest,
} from 'redux-saga/effects';
import {
  getCountriesFromVeeva,
  getProductFamiliesFromVeeva,
  getDeliveryMethodsFromVeeva,
  getDeploymentSuppliersFromVeeva,
  getResourceTypeByCountryFromVeeva,
} from 'api';
import { checkAndFormatAxiosError } from 'api/error';
import { getSessionId } from 'utils';
import {
  getIndependentFiltersDataRequest,
  getIndependentFiltersDataStart,
  getIndependentFiltersDataSuccess,
  getIndependentFiltersDataError,
  getResourceTypeFilterDataRequest,
  getResourceTypeFilterDataStart,
  getResourceTypeFilterDataSuccess,
  getResourceTypeFilterDataError,
  selectCountryIdByName,
} from './filtersSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';

function* independentFiltersSaga(): SagaIterator {
  try {
    yield put(getIndependentFiltersDataStart());
    const sessionId: string = yield call(getSessionId);

    const [
      {
        data: { data: countries },
      },
      {
        data: { data: productFamilies },
      },
      {
        data: { data: deliveryMethods },
      },
      {
        data: { data: deploymentSuppliers },
      },
    ] = yield all([
      call(getCountriesFromVeeva, sessionId),
      call(getProductFamiliesFromVeeva, sessionId),
      call(getDeliveryMethodsFromVeeva, sessionId),
      call(getDeploymentSuppliersFromVeeva, sessionId),
    ]);

    yield put(
      getIndependentFiltersDataSuccess({
        countries,
        productFamilies,
        deliveryMethods,
        deploymentSuppliers,
      }),
    );
  } catch (err) {
    const error = checkAndFormatAxiosError(err);

    yield put(getIndependentFiltersDataError(
      error instanceof Error ? error.message : JSON.stringify(error),
    ));
  }
}

function* resourceTypeByCountrySaga({
  payload,
}: PayloadAction<string>): SagaIterator {
  try {
    yield put(getResourceTypeFilterDataStart());
    const sessionId: string = yield call(getSessionId);
    const countryId: string = yield select(selectCountryIdByName, payload);
    const {
      data: { data },
    } = yield call(getResourceTypeByCountryFromVeeva, sessionId, countryId);

    yield put(
      getResourceTypeFilterDataSuccess({
        resourceTypes: {
          currentCountry: payload,
          data,
        },
      }),
    );
  } catch (err) {
    const error = checkAndFormatAxiosError(err);

    yield put(getResourceTypeFilterDataError(
      error instanceof Error ? error.message : JSON.stringify(error),
    ));
  }
}

export default [
  takeLatest(getIndependentFiltersDataRequest.type, independentFiltersSaga),
  takeLatest(getResourceTypeFilterDataRequest.type, resourceTypeByCountrySaga),
];
