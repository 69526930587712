/* eslint-disable max-len */

import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg
      width="131"
      height="82"
      viewBox="0 0 131 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_131_69454)">
        <rect
          width="130"
          height="82"
          transform="translate(0.5)"
          fill="white"
          fillOpacity="0.01"
        />
        <path
          d="M65.5 81.3914C101.399 81.3914 130.5 75.0143 130.5 67.1479C130.5 59.2814 101.399 52.9044 65.5 52.9044C29.6015 52.9044 0.5 59.2814 0.5 67.1479C0.5 75.0143 29.6015 81.3914 65.5 81.3914Z"
          fill="#F5F5F5"
        />
        <path
          d="M112.219 28.0139L91.6097 4.60981C90.6205 3.01454 89.1763 2.05005 87.6548 2.05005H43.3452C41.8238 2.05005 40.3795 3.01454 39.3903 4.60777L18.7812 28.0159V46.8153H112.219V28.0139Z"
          stroke="#CED4DA"
        />
        <path
          d="M85.0264 34.4661C85.0264 31.2003 87.0455 28.5042 89.55 28.5022H112.219V65.407C112.219 69.7269 109.537 73.2674 106.227 73.2674H24.7734C21.4625 73.2674 18.7812 69.7248 18.7812 65.407V28.5022H41.45C43.9545 28.5022 45.9736 31.1942 45.9736 34.46V34.5048C45.9736 37.7706 48.015 40.4077 50.5175 40.4077H80.4825C82.985 40.4077 85.0264 37.7462 85.0264 34.4804V34.4661V34.4661Z"
          fill="#FAFAFA"
          stroke="#CED4DA"
        />
      </g>
      <defs>
        <clipPath id="clip0_131_69454">
          <rect
            width="130"
            height="82"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
