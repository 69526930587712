import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'assets/images/Logo';
import styles from './NavBar.module.scss';

interface NavBarProps {
  onBrandClick?: () => void;
  textContent?: string;
  children?: React.ReactNode;
  isModuleCombinationLabelShown?: boolean;
  discardPositionFixed?: boolean;
}

const NavBar: React.FC<NavBarProps> = ({
  onBrandClick,
  textContent,
  children,
  isModuleCombinationLabelShown = false,
  discardPositionFixed = false,
}) => {
  const classes = classNames('navbar navbar-expand-md py-2', styles.navBar, { discardPositionFixed });
  // user on simulation page should not be able to click on logo and go to filters
  const clickAction = window.location.pathname.includes('simulation')
    ? (e: React.MouseEvent<Element, MouseEvent>):void => { e.preventDefault(); }
    : onBrandClick;

  return (
    <nav className={classes}>
      <div className="container-fluid justify-content-start mx-sm-1">
        <Link
          className="navbar-brand d-flex align-items-center me-3"
          to="/"
          onClick={clickAction}
        >
          <Logo />
          <div>
            <h1 className={`${styles.heading} ms-2 py-0 mb-0`}>
              Generative Marketing Personalization
            </h1>
            {isModuleCombinationLabelShown && (
              <p className={styles.moduleCombinationLabel}>Module Combination Simulation</p>
            )}
          </div>
        </Link>
        {/* eslint-disable-next-line multiline-ternary */}
        {textContent
          ? <span className={styles.textContent}>{textContent}</span>
          : null
        }
        {children}
      </div>
    </nav>
  );
};

export default NavBar;
