import React from 'react';

const Logo: React.FC = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="3 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_120_90742)">
        <path
          d="M4 6.05225C4 5.49996 4.44772 5.05225 5 5.05225H6.4V10.947H5C4.44771 10.947 4 10.4993 4 9.94698V6.05225Z"
          fill="white"
          fillOpacity="0.5"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter1_d_120_90742)">
        <rect
          x="6.3999"
          y="2.52661"
          width="8.8"
          height="10.9474"
          rx="1"
          fill="white"
          fillOpacity="0.75"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter2_d_120_90742)">
        <rect x="10.3999" width="12" height="16" rx="1" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_120_90742"
          x="0"
          y="5.05225"
          width="10.3999"
          height="13.8948"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_120_90742"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_120_90742"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_120_90742"
          x="2.3999"
          y="2.52661"
          width="16.7998"
          height="18.9473"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_120_90742"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_120_90742"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_120_90742"
          x="6.3999"
          y="0"
          width="20"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_120_90742"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_120_90742"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Logo;
