import { ModulesSection, ModulesBundle } from 'store/modules/types';
import { CombinationSparseArray, isCombinationItemWithGroup } from './useCombinations';

export function validateSectionForCombination(section: ModulesSection): boolean {
  return !!(section.placeholder && section.bundleIndexes.length);
}

export function combinationCreateKey(combination: CombinationSparseArray) : string {
  return combination
    .map((item) => {
      if (!item) {
        return 'x';
      }
      if (isCombinationItemWithGroup(item)) {
        return `${item.bundleIdx}g${item.groupIdx}`;
      }

      return `${item.bundleIdx}${item.moduleIdx}`;
    })
    .join('-');
}

export function combinationGetModuleForSection(
  bundles: ModulesBundle[], combination: CombinationSparseArray, idx: number,
): null | [(ModulesBundle['modules'][number])[], ModulesBundle] {
  const item = combination[idx];
  if (!item) {
    return null;
  }
  const { bundleIdx } = item;

  const bundle = bundles[bundleIdx];
  if (!bundle) {
    return null;
  }

  const modules = isCombinationItemWithGroup(item) ? item.modules : [item.moduleIdx];
  const validModules = modules
    .map(moduleIdx => bundle.modules[moduleIdx])
    .filter(module => Boolean(module));
  if (!validModules.length) {
    return null;
  }

  return [validModules, bundle];
}

type CombinationModule = CombinationSparseArray[number];

const bundleIndexesToString = (indexes: number[]): string => [...indexes].sort((a, b) => a - b).toString();

const populateCombination = (
  combination: CombinationSparseArray, sectionIndexes: number[], layout: CombinationModule,
): CombinationSparseArray => {
  const clone = combination.map(i => i);
  sectionIndexes.forEach((idx) => {
    clone[idx] = { ...layout };
  });

  return clone;
};

export function buildCombinations(
  bundles: ModulesBundle[],
  sections: ModulesSection[],
  selectedOnly = true,
): CombinationSparseArray[] {
  const groupedBundleIndexes = new Map<string, number[]>();
  const sectionsToCompute = sections.flatMap((section, index) => {
    if (!validateSectionForCombination(section)) {
      return [];
    }
    const bundlesKey = bundleIndexesToString(section.bundleIndexes);
    groupedBundleIndexes.set(bundlesKey, section.bundleIndexes);

    return [{ index, bundleIndexes: bundlesKey }];
  });

  if (!groupedBundleIndexes.size) {
    return [];
  }

  let combinations: CombinationSparseArray[] = [ new Array(sections.length) ];

  groupedBundleIndexes.forEach((values, key) => {
    const sectionIndexes = sectionsToCompute.filter(item => item.bundleIndexes === key).map(item => item.index);
    const layouts: CombinationModule[] = values
      .flatMap(
        (bundleIdx) => {
          const { modules, groups } = bundles[bundleIdx];

          return [
            ...modules.filter(module => !module.disabled && (!selectedOnly || module.selected))
              .map(module => ({
                bundleIdx,
                moduleIdx: module.index,
              })),
            ...groups.filter(group => !selectedOnly || group.selected)
              .map(group => ({
                bundleIdx,
                groupIdx: group.index,
                modules: group.modules.map(module => module.index),
              })),
          ];
        },
      )
      .map((module, index) => ({ ...module, index }));

    if (layouts.length) {
      combinations = combinations.flatMap(
        combination => layouts.map(
          (layout) => {
            return populateCombination(combination, sectionIndexes, layout);
          },
        ),
      );
    }
  });

  return combinations;
}

const RECIPE_EMPTY_MODULE = 'empty';

type RecipeCombinationItem = {
  zone: string; // section placeholder
  zoneScreenName: string; // section surface name
  moduleDocNumber: string;
  moduleScreenName?: string;
};

export type RecipeCombination = RecipeCombinationItem[];

export function buildRecipeCombinations(
  combinations: CombinationSparseArray[], bundles: ModulesBundle[], sections: ModulesSection[],
): RecipeCombination[] {
  const list: RecipeCombination[] = Array.from(new Array(combinations.length), () => []);

  sections.forEach((section, sectionIdx) => {
    if (!section.placeholder) {
      return;
    }
    const zone = section.placeholder.name;
    const zoneScreenName = section.surface.name;

    let maxNumberOfModules = 1;
    const modules: RecipeCombinationItem[][] = combinations.map((combination) => {
      if (!section.bundleIndexes.length) {
        return [];
      }
      const data = combinationGetModuleForSection(bundles, combination, sectionIdx);
      if (!data) {
        return [];
      }
      const [sectionModules, sectionBundle] = data;

      maxNumberOfModules = maxNumberOfModules > sectionModules.length ? maxNumberOfModules : sectionModules.length;

      return sectionModules.map(module => ({
        zone,
        zoneScreenName,
        moduleDocNumber: module.number,
        moduleScreenName: sectionBundle.name,
      }));
    });

    modules.forEach((modulesOfCombination, idx) => {
      const emptyItems = Array.from(
        new Array(maxNumberOfModules - modulesOfCombination.length),
        () => ({
          zone,
          zoneScreenName,
          moduleDocNumber: RECIPE_EMPTY_MODULE,
        }),
      );
      list[idx].push(
        ...modulesOfCombination,
        ...emptyItems,
      );
    });

  });

  return list;
}
