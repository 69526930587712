import type { ChannelType, PreviewOptionName, ProjectType } from 'const';

export interface User {
  firstName: string;
  lastName: string;
  userName: string;
  userEmail: string;
}

export interface UserContact {
  name: string;
  email: string;
}

export interface UsersData extends User {
  usersContacts: UserContact[];
}

export interface BaseVeevaEntity {
  id: string;
  name__v: string;
}

export type Country = BaseVeevaEntity;

export type ProductFamily = BaseVeevaEntity;

export interface ResourceType extends BaseVeevaEntity {
  resource_type__c: string;
  country__c: string;
}

export type DeploymentSupplier = BaseVeevaEntity;

export interface DeliveryMethod {
  name: string;
  label: string;
}

export interface IndependentFilters {
  countries: Country[];
  productFamilies: ProductFamily[];
  deliveryMethods: DeliveryMethod[];
  deploymentSuppliers: DeploymentSupplier[];
}

export interface DependentFilters {
  resourceTypes: {
    currentCountry: string;
    data: ResourceType[];
  };
}

export interface SelectedFilters {
  selectedCountry: string;
  selectedProductFamily: string;
  selectedResourceType: string;
  selectedDeliveryMethod: string;
  selectedDeploymentSupplier: string;
}

export interface JobZeroDocument {
  id: string;
  name__v: string;
  document_number__v: string;
  moduleTemplateDocumentNumber: string;
  bundles: ModuleBundle[];
}

export interface ModuleBundle {
  id: string;
  name__v: string;
  document_number__v: string;
}

export interface JobZeroActionPayload {
  sessionId: string;
  productFamily: string;
  country: string;
  resourceType: string;
  deliveryMethod?: string;
  deploymentSupplier?: string;
  onlyFirstPage?: boolean;
}

export interface Section {
  name: string;
  displayName: string;
  placeholder: string;
  surfaceName: string;
  modules: Module[];
}

export interface Module {
  id: string;
  document_number__v: string;
  classification__v: string;
  screenDefinitionName: string;
  url: string;
  moduleIndex: number;
  selected?: boolean;
  disabled?: boolean;
}

export interface ModuleToSelect {
  sectionName: string;
  moduleId: string;
}

export interface ModulesToToggle {
  sectionName: string;
  selected: boolean;
}

export interface ModuleBundleActionPayload {
  documentId: number;
  majorVersion: number;
  minorVersion: number;
}

export interface ModuleTemplate {
  name: string;
  documentId: number;
  majorVersion: number;
  minorVersion: number;
  resourceType: ChannelType;
}

export interface ModuleBundleType {
  name: string;
  documentId: number;
  majorVersion: number;
  minorVersion: number;
  resourceType: ChannelType;
  veevaUrl: string;
  channels: ModuleTemplate[];
}

export interface ModuleBundleDocument {
  id: string;
  name: string;
  resourceType: string;
  veevaUrl: string;
  projectType: ProjectType;
  screens: Screen[];
}

export interface PreviewOption {
  name: PreviewOptionName;
  width: string;
}

export interface Screen {
  id: string;
  name: string;
  screenTypeDisplayName: string;
  screenType: string;
  previewOptions: PreviewOption[];
  sections: TemplateSection[];
}

export interface TemplateSection {
  id: string;
  name: string;
  displayName: string;
  layouts: Layout[];
}

export interface ScreenToView extends TemplateSection {
  sectionIds: string[];
}

export interface Layout {
  id: string;
  name: string;
  number: string;
  classification: string;
  checksum: string;
  documentId: string;
  documentVersionId: string;
  format: string;
  active?: boolean;
}

export const enum AppRoute {
  HOME = '/',
  FILTERS = '/filters',
  MODULES = '/modules',
  COMBINATIONS = '/combinations',
  CHANNELS = '/simulation',
  SIMULATION = '/simulation/combinations',
  IFRAME_PLACEHOLDER = '/iframe-placeholder',
}

export type JobZeroDocumentData = {
  data: JobZeroDocument[];
  nextPage: string | undefined;
};
