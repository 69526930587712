import React from 'react';

import styles from './styles.module.scss';

const IframePlaceholder: React.FunctionComponent = () => (
  <div className={styles.IframePlaceholder}>
    <div className={styles.text}>
      <div>Modular Simulation is opening in another window...</div>
      <div>Click Close Button to return to your previous window.</div>
    </div>
    <div className={styles.hint}>Please make sure that the pop-up window is allowed.</div>
  </div>
);

export default IframePlaceholder;
