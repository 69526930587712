import _ from 'lodash';

const SESSION_ID = 'sessionId';

type MessageData = {
  message_id: string;
  data: {
    session_id: string;
  };
};

export const getSessionId = (): string => {
  return sessionStorage.getItem(SESSION_ID) ?? '';
};

export const setSessionId = (sessionId: string): void => {
  sessionStorage.setItem(SESSION_ID, sessionId);
};

export const removeSessionId = (): void => {
  sessionStorage.removeItem(SESSION_ID);
};

export function getIframeSessionId(): void {
  const MAX_MESSAGES = 3;
  let messageCount = 0;

  function parseEvent({ data }: MessageEvent): void {
    if (!_.isString(data)) {
      return;
    }

    try {
      const { message_id: messageId, data: messageData } = JSON.parse(data) as MessageData;

      if (messageId === 'session_id') {
        window.removeEventListener('message', parseEvent);

        const GMPWindow = window.open(window.location.href, '_blank') as Window;
        GMPWindow.sessionStorage.setItem(SESSION_ID, messageData.session_id);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error occurred during parsing a message: ', error);
    } finally {
      if (messageCount++ > MAX_MESSAGES) {
        window.removeEventListener('message', parseEvent);
      }
    }
  }

  // https://developer.veevavault.com/docs/#sending-session-ids-with-post-message
  window.addEventListener('message', parseEvent);
  window.parent.postMessage(JSON.stringify({ message_id: 'ready', data: {} }), document.referrer);
}
