import { ModulesBundle, ModulesBundleGroup, ModulesPersistentBundleGroups } from 'store/modules/types';

function replaceBundle(
  bundles: ModulesBundle[],
  bundleIndex: number,
  newBundle: ModulesBundle,
): ModulesBundle[] {
  const newState = bundles.map(i => i);

  newState[bundleIndex] = {
    ...newBundle,
    index: bundleIndex,
  };

  return newState;
}

export function replaceGroupsInBundle<T extends ModulesBundle>(
  bundle: T,
  newGroups: ModulesBundleGroup[],
): T {
  const createModulesSignature = (group: ModulesBundleGroup): string => {
    return group.modules.map(module => module.index).toString();
  };

  const groupsWithCheckedDuplicates = newGroups.map((group) => {
    const signature = createModulesSignature(group);
    const hasDuplicates = newGroups.some(item => item !== group && signature === createModulesSignature(item));

    return { ...group, hasDuplicates };
  });

  return { ...bundle, groups: groupsWithCheckedDuplicates };
}

export function replaceBundleGroups(
  bundles: ModulesBundle[],
  bundleIndex: number,
  groups: ModulesBundleGroup[],
): ModulesBundle[] {
  const bundle = bundles[bundleIndex];
  if (!bundle) {
    return bundles;
  }
  const updatedBundle = replaceGroupsInBundle(bundle, groups);

  return replaceBundle(bundles, bundleIndex, updatedBundle);
}

export function addBundleGroup(
  bundles: ModulesBundle[],
  bundleIndex: number,
  moduleIndexes: number[],
): ModulesBundle[] {
  if (!bundles[bundleIndex]) {
    return bundles;
  }

  const modules = (bundles[bundleIndex]?.modules ?? [])
    .filter(module => moduleIndexes.includes(module.index))
    .map(module => ({ ...module }));

  const { groups } = bundles[bundleIndex];
  const newGroup: ModulesBundleGroup = {
    index: groups.length,
    hasDuplicates: false,
    selected: false,
    modules,
  };
  const updatedGroups = groups.map(i => i); // for sparse array
  updatedGroups[newGroup.index] = newGroup;

  return replaceBundleGroups(bundles, bundleIndex, updatedGroups);
}

export function getPersistentBundlesGroups(
  bundles: ModulesBundle[],
): ModulesPersistentBundleGroups[] {
  return bundles.flatMap((bundle): ModulesPersistentBundleGroups | [] => {
    const groups = bundle.groups.filter(i => i);
    if (!groups.length) {
      return [];
    }

    return {
      bundleIndex: bundle.index,
      groups: groups.map(group => group.modules.map(module => module.index)),
    };
  });
}
