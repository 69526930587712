/* eslint-disable max-len */

import React from 'react';

const DownAngleArrow: React.FC = () => {
  return (
    <svg viewBox="0 0 20 20">
      <g>
        <path fill="#212529" d="M2.05715 5.80666C2.11521 5.74846 2.18418 5.70228 2.26011 5.67077C2.33604 5.63926 2.41744 5.62305 2.49965 5.62305C2.58186 5.62305 2.66326 5.63926 2.73919 5.67077C2.81513 5.70228 2.8841 5.74846 2.94215 5.80666L9.99965 12.8654L17.0572 5.80666C17.1153 5.74855 17.1842 5.70246 17.2602 5.67101C17.3361 5.63956 17.4175 5.62337 17.4997 5.62337C17.5818 5.62337 17.6632 5.63956 17.7391 5.67101C17.8151 5.70246 17.884 5.74855 17.9422 5.80666C18.0003 5.86477 18.0464 5.93376 18.0778 6.00968C18.1093 6.08561 18.1254 6.16698 18.1254 6.24916C18.1254 6.33134 18.1093 6.41272 18.0778 6.48864C18.0464 6.56457 18.0003 6.63355 17.9422 6.69166L10.4422 14.1917C10.3841 14.2499 10.3151 14.296 10.2392 14.3276C10.1633 14.3591 10.0819 14.3753 9.99965 14.3753C9.91744 14.3753 9.83604 14.3591 9.76011 14.3276C9.68418 14.296 9.61521 14.2499 9.55715 14.1917L2.05715 6.69166C1.99895 6.63361 1.95277 6.56464 1.92126 6.48871C1.88975 6.41277 1.87354 6.33137 1.87354 6.24916C1.87354 6.16695 1.88975 6.08555 1.92126 6.00962C1.95277 5.93369 1.99895 5.86472 2.05715 5.80666Z"/>
      </g>
    </svg>
  );
};

export default DownAngleArrow;
