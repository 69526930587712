/* eslint-disable max-len */

import React from 'react';

const ArrowLeft: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_112_131519)">
        <rect width="16" height="16" fill="white" fillOpacity="0.01" />
        <path
          className="svg-arrow-path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9996 7.99917C14.9996 7.86656 14.947 7.73938 14.8532 7.64561C14.7594 7.55185 14.6323 7.49917 14.4996 7.49917H2.70664L5.85364 4.35317C5.90013 4.30668 5.93701 4.25149 5.96217 4.19075C5.98732 4.13001 6.00027 4.06491 6.00027 3.99917C6.00027 3.93342 5.98732 3.86832 5.96217 3.80758C5.93701 3.74684 5.90013 3.69165 5.85364 3.64517C5.80715 3.59868 5.75197 3.5618 5.69123 3.53664C5.63049 3.51148 5.56539 3.49854 5.49964 3.49854C5.4339 3.49854 5.3688 3.51148 5.30806 3.53664C5.24732 3.5618 5.19213 3.59868 5.14564 3.64517L1.14564 7.64517C1.09908 7.69161 1.06214 7.74679 1.03693 7.80753C1.01172 7.86828 0.998749 7.9334 0.998749 7.99917C0.998749 8.06493 1.01172 8.13005 1.03693 8.1908C1.06214 8.25154 1.09908 8.30672 1.14564 8.35317L5.14564 12.3532C5.19213 12.3997 5.24732 12.4365 5.30806 12.4617C5.3688 12.4868 5.4339 12.4998 5.49964 12.4998C5.56539 12.4998 5.63049 12.4868 5.69123 12.4617C5.75197 12.4365 5.80715 12.3997 5.85364 12.3532C5.90013 12.3067 5.93701 12.2515 5.96217 12.1908C5.98732 12.13 6.00027 12.0649 6.00027 11.9992C6.00027 11.9334 5.98732 11.8683 5.96217 11.8076C5.93701 11.7468 5.90013 11.6917 5.85364 11.6452L2.70664 8.49917H14.4996C14.6323 8.49917 14.7594 8.44649 14.8532 8.35272C14.947 8.25895 14.9996 8.13177 14.9996 7.99917Z"
          fill="#00857C"
        />
      </g>
      <defs>
        <clipPath id="clip0_112_131519">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowLeft;
