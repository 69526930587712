import React, { forwardRef } from 'react';
import { JobZeroDocument } from 'types';
import styles from '../Filters.module.scss';

type Props = {
  jobZeroData: JobZeroDocument[];
  lastSelectedDocumentId: string;
  onJobZeroSelectHandler: (jobZeroId: string, moduleBundleId: string) => void;
};

// eslint-disable-next-line react/display-name
const JobZeroContent = forwardRef<HTMLTableRowElement, Props>((props, ref) => {
  const { jobZeroData, lastSelectedDocumentId, onJobZeroSelectHandler } = props;

  return (
    <>
      {jobZeroData.map(item => (
        <tr key={item.document_number__v} ref={lastSelectedDocumentId === item.id ? ref : null}>
          <td>{item.document_number__v}</td>
          <td>{item.name__v}</td>
          <td>
            <div className="d-flex align-items-center justify-content-between">
              {item.bundles[0] ? item.bundles[0].name__v : 'No bundles found'}
              {item.bundles[0] ? <button
                className={`flex-shrink-0 ${styles.link}`}
                onClick={(): void => {
                  onJobZeroSelectHandler(item.id, item.bundles[0].id);
                }}
              >
                Select
              </button> : ''}
            </div>
          </td>
        </tr>
      ))}
    </>
  );
});

export default JobZeroContent;
