import React from 'react';
import { Combobox } from 'react-widgets/esm';
import Button from 'components/Button/Button';
import {
  selectCountriesNames,
  selectProductFamiliesNames,
  selectResourceTypesNames,
  selectDeliveryMethodsNames,
  selectDeploymentSuppliersNames,
  selectIndependentFiltersLoading,
  selectResourceTypesLoading,
} from 'store/filtersSlice';
import { useAppSelector } from 'store/hooks';
import styles from '../Filters.module.scss';
import { useFilters } from '../useFilters';

export const submitButtonTestId = 'submittButtonTestId';

type Props = {
  onJobZeroRequestSubmitted: (isSubmitted: boolean) => void;
};

const FilterBar: React.FC<Props> = ({ onJobZeroRequestSubmitted }) => {
  const productFamilies = useAppSelector(selectProductFamiliesNames);
  const countries = useAppSelector(selectCountriesNames);
  const resourceTypes = useAppSelector(selectResourceTypesNames);
  const deliveryMethods = useAppSelector(selectDeliveryMethodsNames);
  const deploymentSuppliers = useAppSelector(selectDeploymentSuppliersNames);
  const independentFiltersLoading = useAppSelector(
    selectIndependentFiltersLoading,
  );
  const resourceTypesLoading = useAppSelector(selectResourceTypesLoading);
  const {
    country,
    productFamily,
    resourceType,
    deliveryMethod,
    deploymentSupplier,
    isButtonDisabled,
    onCountryChangeHandler,
    onProductFamilyChangeHandler,
    onResourceTypeChangeHandler,
    onDeliveryMethodChangeHandler,
    onDeploymentSupplierChangeHandler,
    onFormSubmitHandler,
  } = useFilters(onJobZeroRequestSubmitted);

  return (
    <form className={styles.form} onSubmit={onFormSubmitHandler}>
      <Combobox
        busy={independentFiltersLoading}
        data={productFamilies}
        filter="contains"
        placeholder="Product*"
        value={productFamily}
        onChange={onProductFamilyChangeHandler}
      />
      <Combobox
        busy={independentFiltersLoading}
        data={countries}
        filter="contains"
        placeholder="Country*"
        value={country}
        onChange={onCountryChangeHandler}
      />
      <Combobox
        busy={resourceTypesLoading}
        data={resourceTypes}
        filter="contains"
        placeholder="Resource type*"
        value={resourceType}
        onChange={onResourceTypeChangeHandler}
        disabled={!country}
      />
      <Combobox
        busy={independentFiltersLoading}
        data={deliveryMethods}
        filter="contains"
        placeholder="Method of delivery"
        value={deliveryMethod}
        onChange={onDeliveryMethodChangeHandler}
      />
      <Combobox
        busy={independentFiltersLoading}
        data={deploymentSuppliers}
        filter="contains"
        placeholder="Deployment supplier"
        value={deploymentSupplier}
        onChange={onDeploymentSupplierChangeHandler}
      />
      <Button
        type="submit"
        isDisabled={isButtonDisabled}
        label="Filter"
        styleOverride={{ width: '100%' }}
        dataTestId={submitButtonTestId}
      />
    </form>
  );
};

export default FilterBar;
