import React, { useLayoutEffect, useRef } from 'react';
import Accordion from 'components/Accordion';
import Artboard from 'components/Artboard';
import NavigationScreens from 'components/NavigationScreens';
import store from 'store';
import { useAppDispatch } from 'store/hooks';
import { SimulationScrollSide } from 'store/simulation';
import {
  setScrollPosition,
  selectScrollPositions,
  addLayoutOnSection,
  removeLayoutOnSection,
} from 'store/simulationSlice';
import { ModuleBundleDocument, TemplateSection, Layout, ScreenToView } from 'types';
import { eventEmitter, EVENTS } from 'utils/eventEmitter';
import styles from '../styles.module.scss';

interface SimulationDataProps {
  template: ModuleBundleDocument;
  moduleBundleLeftPanel: ScreenToView[];
}

const SimulationData: React.FC<SimulationDataProps> = ({ template, moduleBundleLeftPanel }) => {
  const dispatch = useAppDispatch();

  const screensRefs = useRef<Record<string, HTMLDivElement>>({});
  const onScreenClick = (screenId: string): void => {
    const scrollToScreen = screensRefs.current[screenId];
    scrollToScreen?.scrollIntoView({ behavior: 'smooth' });
  };

  const clickOnRendition = (screen: ScreenToView, layout: Layout | null): void => {
    for (const sectionId of screen.sectionIds) {
      const action = layout
        ? addLayoutOnSection({ entityId: sectionId, layout, shouldRemoveLayout: true })
        : removeLayoutOnSection({ entityId: sectionId });
      dispatch(action);
    }
    eventEmitter.emit(EVENTS.SCROLL_SECTION_RIGHT, screen.sectionIds[0]);
  };

  const onScroll = (side: SimulationScrollSide, event: React.UIEvent<HTMLDivElement>): void => {
    dispatch(setScrollPosition({ side, value: event.currentTarget.scrollTop }));
  };

  const onChangeLayoutVariant = (section: TemplateSection, layout: Layout | null, screen: ScreenToView): void => {
    for (const sectionId of screen.sectionIds) {
      dispatch(layout
        ? addLayoutOnSection({ entityId: sectionId, layout })
        : removeLayoutOnSection({ entityId: sectionId }),
      );
    }
  };

  const leftPanelRef = useRef<HTMLDivElement>(null);
  const rightPanelRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const scrollPositions = selectScrollPositions(store.getState());
    // wait two seconds for all images to load.
    // should be removed when heights of images would be saved to store and persisted
    setTimeout(() => {
      leftPanelRef.current?.scrollTo({ top: scrollPositions.left });
      rightPanelRef.current?.scrollTo({ top: scrollPositions.right });
    }, 2000);
  }, []);

  return (<div className={styles.Container}>
    <div
      ref={leftPanelRef}
      onScroll={(event): void => onScroll(SimulationScrollSide.LEFT, event)}
      className={styles.LeftPanel}
    >
      <Accordion
        screens={moduleBundleLeftPanel}
        scrollContainer={leftPanelRef}
        onItemClick={clickOnRendition}
        onClearButtonClick={ (section): void => clickOnRendition(section, null)}
      />
    </div>
    <div className={styles.RightPanel}>
      <NavigationScreens
        screens={template?.screens}
        onScreenClick={onScreenClick}
      />
      <Artboard
        screens={template?.screens}
        projectType={template?.projectType}
        screensRefs={screensRefs}
        onScroll={(event): void => onScroll(SimulationScrollSide.RIGHT, event)}
        onCnahgeLayoutVariant={onChangeLayoutVariant}
        ref={rightPanelRef}
      />
    </div>
  </div>);
};

export default SimulationData;
