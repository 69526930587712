import type { ModuleBundleDocument, TemplateSection, ScreenToView } from 'types';
import { filterModuleBundleLayouts } from './filterModuleBundleLayouts';

export const mergeSimulationCombinations = (
  bundle: ModuleBundleDocument,
  templateSections: TemplateSection[],
): ScreenToView[] => {
  const formattedScreens = filterModuleBundleLayouts(bundle?.screens);

  return formattedScreens.reduce((acc, screen) => {
    const sections = templateSections.filter((templateSection) => {
      const splitedScreenTypeByWords = screen.displayName.toLocaleLowerCase().split(' ');
      const splitedSectionTypeByWords = templateSection.displayName.toLocaleLowerCase().split(' ');

      return splitedScreenTypeByWords.every(word => splitedSectionTypeByWords.includes(word));
    });

    if (sections.length && screen.layouts.length) {
      const sectionsIds = sections.map(section => section.id);
      const screenWithCorrespondingSectionId: ScreenToView = { ...screen, sectionIds: sectionsIds };
      acc.push(screenWithCorrespondingSectionId);
    }

    return acc;
  }, [] as ScreenToView[]);
};
