import { createAction, createSlice } from '@reduxjs/toolkit';
import { SAGA_STATUS, ChannelType } from 'const';
import type { ModuleBundleType, ModuleTemplate, ModuleBundleActionPayload } from 'types';
import type { RootState } from './index';

interface ModuleBundleState {
  moduleBundle: ModuleBundleType;
  status: SAGA_STATUS;
  error: string;
}

const initialState: ModuleBundleState = {
  moduleBundle: {
    name: '',
    documentId: 0,
    majorVersion: 0,
    minorVersion: 0,
    resourceType: '' as ChannelType,
    veevaUrl: '',
    channels: [] as ModuleTemplate[],
  },
  status: SAGA_STATUS.IDLE,
  error: '',
};

export const channelsSlice = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    getModuleBundleChannelsDataStart: (state) => {
      state.status = SAGA_STATUS.PENDING;
      state.error = '';
    },
    getModuleBundleChannelsDataSuccess: (
      state,
      action,
    ) => {
      state.status = SAGA_STATUS.IDLE;
      state.moduleBundle = action.payload;
    },
    getModuleBundleChannelsDataError: (
      state,
      action,
    ) => {
      state.status = SAGA_STATUS.IDLE;
      state.error = action.payload;
    },
  },
});

export const selectModuleBundleDataLoadingStatus = (state: RootState): boolean =>
  state.channels.status === SAGA_STATUS.PENDING;

export const getModuleBundleChannelsDataRequest = createAction<ModuleBundleActionPayload>(
  'getModuleBundleChannelsDataRequest',
);

export const selectModuleBundle = (state: RootState): ModuleBundleType => state.channels.moduleBundle;

export const {
  getModuleBundleChannelsDataStart,
  getModuleBundleChannelsDataSuccess,
  getModuleBundleChannelsDataError,
} = channelsSlice.actions;

export default channelsSlice.reducer;
