import classNames from 'classnames';
import React from 'react';
import { CLEAR_ZONE_BUTTON } from 'const';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  onClick: () => void;
}

const CancelPanel: React.FC<Props> = ({ className, onClick }) => {
  return (
    <div className={classNames(styles.cancelPanel, className)} data-testid="simulation-section-cancel-panel">
      <button
        type='button'
        className="btn btn-sm btn-light shadow"
        onClick={onClick}
      >
        {CLEAR_ZONE_BUTTON}
      </button>
    </div>
  );
};

export default CancelPanel;
