import { ModuleBundleDocument } from 'types';
import { API } from './constants';
import { checkAndFormatAxiosError } from './error';
import { apiInstance, getAuthorizationHeader } from './index';

type Payload = {
  documentId: number;
  majorVersion: number;
  minorVersion: number;
};

export async function getModuleBundleSimulation(
  sessionId: string,
  payload: Payload,
): Promise<ModuleBundleDocument> {
  try {
    const { documentId, majorVersion, minorVersion } = payload;
    const url = `${documentId}/${majorVersion}/${minorVersion}`;

    const { data } = await apiInstance.get<ModuleBundleDocument>(
      `${API.GET_MODULE_BUNDLE_SIMULATION}/${url}`,
      {
        headers: {
          ...getAuthorizationHeader(sessionId),
        },
      },
    );

    return data;
  } catch (error) {
    throw checkAndFormatAxiosError(error);
  }
}
