import { API } from './constants';
import { checkAndFormatAxiosError } from './error';
import { apiInstance, getAuthorizationHeader } from './index';

export interface SendRecipePayload {
  header: {
    jobZeroDocNumber: string;
    jobZeroDocName: string;
    moduleBundleDocNumber: string;
    moduleBundleDocName: string;
    modularTemplateDocNumber: string;
    ownerUserName: string;
    ownerEmail: string;
    estimatedReviewStartDate: string;
    originator: string;
    timestamp: string;
  };
  // one recipe consists of array of modules - single permutation
  // several recipes will consists of array of array of modules - array of permutations
  permutations: {
    zone: string;
    zoneScreenName: string;
    moduleDocNumber: string;
    moduleScreenName?: string;
  }[][];
}

export const sendRecipe = async (
  sessionId: string,
  payload: SendRecipePayload,
): Promise<boolean> => {
  try {
    const { data } = await apiInstance.post<{ success: boolean }>(
      API.SEND_RECIPE,
      payload,
      { headers: getAuthorizationHeader(sessionId) },
    );

    return data.success;
  } catch (error) {
    throw checkAndFormatAxiosError(error);
  }
};
