import React from 'react';
import LoadingSpinner from 'components/LoadingSpinner';

const Spinner: React.FC = () => (
  <tr>
    <td className="border-0" colSpan={3}>
      <div className="d-flex flex-column align-items-center my-5">
        <LoadingSpinner />
      </div>
    </td>
  </tr>
);

export default Spinner;
