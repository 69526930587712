import EventEmitter from 'events';

export enum EVENTS {
  EXPAND_ACCORDION = 'expand.accordion',
  FOCUS_SECTION_RIGHT = 'focus.section-right',
  SCROLL_SECTION_RIGHT = 'scroll.section-right',
}

export const eventEmitter = new EventEmitter();
// eslint-disable-next-line jest/require-hook
eventEmitter.setMaxListeners(1000); // by default 10
