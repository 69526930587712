import { ChannelType } from 'const';

const resourceTypeValues = Object.values(ChannelType);
const resourceTypeLength = resourceTypeValues.length;

export function sortModuleBundlesTemplatesByResourceType(resourceType: ChannelType): number {
  const index = resourceTypeValues.indexOf(resourceType);

  return index !== -1 ? index : resourceTypeLength;
}
