import { ScreenToView, TemplateSection } from 'types';

export const sortScreensByTemplates = (
  screens: ScreenToView[],
  templateSections: TemplateSection[],
): ScreenToView[] => {
  let sortedScreens: ScreenToView[] = [];
  templateSections.forEach((templateSection) => {
    const filteredScreens = screens.filter(screen =>
      screen.sectionIds.includes(templateSection.id)
      && !sortedScreens.some(sortedScreen => sortedScreen.id === screen.id),
    );
    sortedScreens = [...sortedScreens, ...filteredScreens];
  });

  return sortedScreens;
};
