import { put, call, select, takeLatest } from 'redux-saga/effects';
import {
  GetJobZeroDocumentsFromVeevaPayload,
  getJobZeroDocumentsFromVeeva,
} from 'api/getJobZeroDocumentsFromVeeva';
import type {
  JobZeroActionPayload, JobZeroDocumentData,
} from 'types';
import {
  selectCountryIdByName,
  selectDeploymentSupplierIdByName,
  selectProductIdByName,
  selectResourceTypeIdByName,
} from './filtersSlice';
import {
  getJobZeroError,
  getJobZeroRequest,
  getJobZeroStart,
  getJobZeroSuccess,
  selectJobZeroDataNextPage,
} from './jobZeroSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';

function* jobZeroSaga({
  payload,
}: PayloadAction<JobZeroActionPayload>): SagaIterator {
  try {
    yield put(getJobZeroStart());
    const countryId = yield select(selectCountryIdByName, payload.country);
    const nextPage = yield select(selectJobZeroDataNextPage);
    const productId = yield select(
      selectProductIdByName,
      payload.productFamily,
    );
    const resourceId = yield select(
      selectResourceTypeIdByName,
      payload.resourceType,
    );

    let deploymentSupplierId = '';

    if (payload.deploymentSupplier) {
      deploymentSupplierId = yield select(
        selectDeploymentSupplierIdByName,
        payload.deploymentSupplier,
      );
    }

    const requestPayload: GetJobZeroDocumentsFromVeevaPayload = {
      sessionId: payload.sessionId,
      productId,
      countryId,
      resourceId,
      nextPage,
    };

    if (payload.deliveryMethod) {
      requestPayload.methodOfDelivery = payload.deliveryMethod;
    }

    if (deploymentSupplierId) {
      requestPayload.deploymentSupplierId = deploymentSupplierId;
    }

    const data: JobZeroDocumentData = yield call(getJobZeroDocumentsFromVeeva, requestPayload);

    yield put(getJobZeroSuccess(data));
  } catch (err) {
    yield put(getJobZeroError(err instanceof Error ? err.message : JSON.stringify(err)));
  }
}

export default [takeLatest(getJobZeroRequest.type, jobZeroSaga)];
