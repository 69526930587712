/* eslint-disable max-len */

import React from 'react';

const ExclamationBadge: React.FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_302_185003)">
        <rect width="14" height="14" fill="white" fillOpacity="0.01" />
        <g clipPath="url(#clip1_302_185003)">
          <path
            d="M6.99976 13.1241C5.3753 13.1241 3.81739 12.4788 2.66873 11.3302C1.52007 10.1815 0.874756 8.6236 0.874756 6.99915C0.874756 5.37469 1.52007 3.81678 2.66873 2.66812C3.81739 1.51946 5.3753 0.874146 6.99976 0.874146C8.62421 0.874146 10.1821 1.51946 11.3308 2.66812C12.4794 3.81678 13.1248 5.37469 13.1248 6.99915C13.1248 8.6236 12.4794 10.1815 11.3308 11.3302C10.1821 12.4788 8.62421 13.1241 6.99976 13.1241ZM6.99976 13.9991C8.85627 13.9991 10.6367 13.2616 11.9495 11.9489C13.2623 10.6361 13.9998 8.85566 13.9998 6.99915C13.9998 5.14263 13.2623 3.36215 11.9495 2.0494C10.6367 0.736644 8.85627 -0.000854492 6.99976 -0.000854492C5.14324 -0.000854492 3.36276 0.736644 2.05001 2.0494C0.737254 3.36215 -0.000244141 5.14263 -0.000244141 6.99915C-0.000244141 8.85566 0.737254 10.6361 2.05001 11.9489C3.36276 13.2616 5.14324 13.9991 6.99976 13.9991Z"
            fill="#DC3545"
          />
          <path
            d="M6.12651 9.62415C6.12651 9.50924 6.14914 9.39546 6.19311 9.2893C6.23708 9.18314 6.30154 9.08668 6.38279 9.00543C6.46404 8.92418 6.5605 8.85972 6.66666 8.81575C6.77282 8.77178 6.8866 8.74915 7.00151 8.74915C7.11641 8.74915 7.23019 8.77178 7.33635 8.81575C7.44251 8.85972 7.53897 8.92418 7.62022 9.00543C7.70148 9.08668 7.76593 9.18314 7.8099 9.2893C7.85387 9.39546 7.87651 9.50924 7.87651 9.62415C7.87651 9.85621 7.78432 10.0788 7.62022 10.2429C7.45613 10.407 7.23357 10.4991 7.00151 10.4991C6.76944 10.4991 6.54688 10.407 6.38279 10.2429C6.21869 10.0788 6.12651 9.85621 6.12651 9.62415ZM6.21226 4.36977C6.2006 4.25938 6.21229 4.14778 6.24656 4.04219C6.28082 3.93661 6.3369 3.83942 6.41116 3.75691C6.48542 3.6744 6.57619 3.60843 6.67759 3.56327C6.77899 3.51811 6.88875 3.49477 6.99976 3.49477C7.11076 3.49477 7.22052 3.51811 7.32192 3.56327C7.42332 3.60843 7.51409 3.6744 7.58835 3.75691C7.66261 3.83942 7.71869 3.93661 7.75296 4.04219C7.78722 4.14778 7.79891 4.25938 7.78726 4.36977L7.48101 7.4384C7.47072 7.55895 7.41556 7.67124 7.32644 7.75308C7.23733 7.83491 7.12074 7.88032 6.99976 7.88032C6.87877 7.88032 6.76219 7.83491 6.67307 7.75308C6.58395 7.67124 6.5288 7.55895 6.51851 7.4384L6.21226 4.36977Z"
            fill="#DC3545"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_302_185003">
          <rect width="14" height="14" fill="white" />
        </clipPath>
        <clipPath id="clip1_302_185003">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExclamationBadge;
