export const API = Object.freeze({
  AUTHORIZE_VEEVA_SESSION: '/authorizeVeevaSession',
  GET_USER_AUTH_TYPE: '/getUserAuthType',
  GET_USER_INFO_FROM_VEEVA: '/getUserInfoFromVeeva',
  GET_DATA_FROM_VEEVA: '/getDataFromVeeva',
  GET_DOCUMENT_DATA_FROM_VEEVA: '/getDocumentDataFromVeeva',
  GET_MODULES_FROM_VEEVA: '/getModulesFromVeeva',
  SEND_RECIPE: '/sendRecipe',
  GET_MODULE_BUNDLE_CHANNELS: '/reviewers-mode/channels',
  GET_MODULE_BUNDLE_SIMULATION: '/reviewers-mode/modules',
  GET_RENDITION: '/rendition',
});

export enum CONTENT_TYPE {
  COUNTRY = 'country',
  PRODUCT_FAMILY = 'productFamily',
  RESOURCE_TYPE_BY_COUNTRY = 'resourceTypeByCountry',
  DELIVERY_METHOD = 'methodOfDelivery',
  DEPLOYMENT_SUPPLIER = 'deploymentSupplierName',
  RESOURCE_TYPE = 'resourceType',
}

export enum AUTH_TYPE {
  SSO = 'sso',
  PASSWORD = 'password',
}

const API_DOMAIN: Record<string, string> = {
  dev: 'api-generative-marketingpersonalization.dev.msd-gmp.com',
  qa: 'api-generative-marketingpersonalization-qa.dev.msd-gmp.com',
  uat: 'api-generative-marketingpersonalization.uat.msd-gmp.com',
  prod: 'api-generative-marketingpersonalization.msd-gmp.com',
};

export const OAUTH2_URL: Record<string, string> = {
  dev: 'https://pingfed-ut.merck.com/as/authorization.oauth2',
  uat: 'https://pingfed-ut.merck.com/as/authorization.oauth2',
  prod: 'https://pingfed.merck.com/as/authorization.oauth2',
};

const stage = process.env.REACT_APP_STAGE;

if (!stage) {
  throw Error('Undefined REACT_APP_STAGE');
}

export const BACKEND_URL = `https://${API_DOMAIN[stage]}`;
