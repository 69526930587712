import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';
import type {
  IndependentFilters,
  DependentFilters,
  Country,
  ProductFamily,
  DeliveryMethod,
  DeploymentSupplier,
  ResourceType,
  SelectedFilters,
} from 'types';
import type { RootState } from './index';

interface FiltersState {
  independentFilters: {
    filters: IndependentFilters;
    status: SAGA_STATUS;
    error: string;
  };
  dependentFilters: {
    filters: DependentFilters;
    status: SAGA_STATUS;
    error: string;
  };
  selectedFilters: {
    selectedCountry: string;
    selectedProductFamily: string;
    selectedResourceType: string;
    selectedDeliveryMethod: string;
    selectedDeploymentSupplier: string;
  };
}

export const initialState: FiltersState = {
  independentFilters: {
    filters: {
      countries: [],
      productFamilies: [],
      deliveryMethods: [],
      deploymentSuppliers: [],
    },
    status: SAGA_STATUS.IDLE,
    error: '',
  },
  dependentFilters: {
    filters: {
      resourceTypes: {
        currentCountry: '',
        data: [],
      },
    },
    status: SAGA_STATUS.IDLE,
    error: '',
  },
  selectedFilters: {
    selectedCountry: '',
    selectedProductFamily: '',
    selectedResourceType: '',
    selectedDeliveryMethod: '',
    selectedDeploymentSupplier: '',
  },
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    getIndependentFiltersDataStart: (state) => {
      state.independentFilters.status = SAGA_STATUS.PENDING;
      state.independentFilters.error = '';
    },
    getIndependentFiltersDataSuccess: (
      state,
      action: PayloadAction<IndependentFilters>,
    ) => {
      state.independentFilters.status = SAGA_STATUS.IDLE;
      state.independentFilters.filters = action.payload;
    },
    getIndependentFiltersDataError: (state, action: PayloadAction<string>) => {
      state.independentFilters.status = SAGA_STATUS.IDLE;
      state.independentFilters.error = action.payload;
    },
    getResourceTypeFilterDataStart: (state) => {
      state.dependentFilters.status = SAGA_STATUS.PENDING;
      state.dependentFilters.error = '';
    },
    getResourceTypeFilterDataSuccess: (
      state,
      action: PayloadAction<DependentFilters>,
    ) => {
      state.dependentFilters.status = SAGA_STATUS.IDLE;
      state.dependentFilters.filters = action.payload;
    },
    getResourceTypeFilterDataError: (state, action: PayloadAction<string>) => {
      state.dependentFilters.status = SAGA_STATUS.IDLE;
      state.dependentFilters.error = action.payload;
    },
    setSelectedFilters: (state, action: PayloadAction<SelectedFilters>) => {
      state.selectedFilters = action.payload;
    },
    resetFilters: () => initialState,
  },
});

export const getIndependentFiltersDataRequest = createAction(
  'getIndependentFiltersDataRequest',
);

export const getResourceTypeFilterDataRequest = createAction<string>(
  'getResourceTypeFilterDataRequest',
);

export const selectIndependentFiltersLoading = (state: RootState): boolean =>
  state.filters.independentFilters.status === SAGA_STATUS.PENDING;

export const selectResourceTypesLoading = (state: RootState): boolean =>
  state.filters.dependentFilters.status === SAGA_STATUS.PENDING;

export const selectIndependentFiltersError = (state: RootState): string =>
  state.filters.independentFilters.error;

export const selectResourceTypesError = (state: RootState): string =>
  state.filters.dependentFilters.error;

export const selectSelectedFilters = (state: RootState): SelectedFilters =>
  state.filters.selectedFilters;

const selectCountries = (state: RootState): Country[] =>
  state.filters.independentFilters.filters.countries;

export const selectCountriesNames = createSelector(
  [selectCountries],
  countries => countries.map(country => country.name__v),
);

export const selectCountryIdByName = (
  state: RootState,
  countryName: string,
): string => {
  const countries = state.filters.independentFilters.filters.countries.filter(
    country => country.name__v === countryName,
  );

  return countries.length ? countries[0].id : '';
};

export const selectProductIdByName = (
  state: RootState,
  productName: string,
): string => {
  const products = state.filters.independentFilters.filters.productFamilies;

  return products.filter(product => product.name__v === productName)[0].id;
};

export const selectResourceTypeIdByName = (
  state: RootState,
  resourceTypeName: string,
): string => {
  const resourceTypes =
    state.filters.dependentFilters.filters.resourceTypes.data;

  return resourceTypes.filter(
    resourceType => resourceType.name__v === resourceTypeName,
  )[0].id;
};

export const selectDeploymentSupplierIdByName = (
  state: RootState,
  deploymentSupplierName: string,
): string => {
  const deploymentSuppliers =
    state.filters.independentFilters.filters.deploymentSuppliers;

  return deploymentSuppliers.filter(
    deploymentSupplier => deploymentSupplier.name__v === deploymentSupplierName,
  )[0].id;
};

const selectProductFamilies = (state: RootState): ProductFamily[] =>
  state.filters.independentFilters.filters.productFamilies;

export const selectProductFamiliesNames = createSelector(
  [selectProductFamilies],
  productFamilies => productFamilies.map(productFamily => productFamily.name__v),
);

const selectDeliveryMethods = (state: RootState): DeliveryMethod[] =>
  state.filters.independentFilters.filters.deliveryMethods;

export const selectDeliveryMethodsNames = createSelector(
  [selectDeliveryMethods],
  deliveryMethods => deliveryMethods.map(deliveryMethod => deliveryMethod.label),
);

const selectDeploymentSuppliers = (state: RootState): DeploymentSupplier[] =>
  state.filters.independentFilters.filters.deploymentSuppliers;

export const selectDeploymentSuppliersNames = createSelector(
  [selectDeploymentSuppliers],
  deploymentSuppliers =>
    deploymentSuppliers.map(deploymentSupplier => deploymentSupplier.name__v),
);

const selectResourceTypes = (state: RootState): ResourceType[] =>
  state.filters.dependentFilters.filters.resourceTypes.data;

export const selectResourceTypesNames = createSelector(
  [selectResourceTypes],
  resourceTypes => resourceTypes.map(resourceType => resourceType.name__v),
);

export const {
  getIndependentFiltersDataStart,
  getIndependentFiltersDataSuccess,
  getIndependentFiltersDataError,
  getResourceTypeFilterDataStart,
  getResourceTypeFilterDataSuccess,
  getResourceTypeFilterDataError,
  setSelectedFilters,
  resetFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
