import React from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import NavBar from 'components/NavBar/NavBar';

const Loading: React.FC = () => {
  return (
    <>
      <NavBar />
      <main
        className='d-flex flex-column align-items-center justify-content-center'
      >
        <LoadingSpinner />
      </main>
    </>
  );
};

export default Loading;
