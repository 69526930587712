import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import Switcher from 'components/Switcher/Switcher';
import styles from './index.module.scss';

type Props = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  size?: 'xl';
  title?: string;
  toggleText?: string;
  toggleHandler?: (value: boolean) => void;
}>;

const Modal: React.FC<Props> = (props) => {
  const {
    children,
    isOpen,
    onClose,
    size,
    title,
    toggleText,
    toggleHandler,
  } =
    props;

  if (!isOpen) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <div className="modal-backdrop fade show" />,
        document.getElementById('backdrop-root') as HTMLDivElement,
      )}
      {ReactDOM.createPortal(
        <div
          className={`modal ${styles.modal}`}
          onClick={onClose}
          role="none"
        >
          <div
            className={`modal-dialog modal-dialog-scrollable modal-dialog-centered modal-${
              size ?? ''
            }`}
            onClick={(event): void => event.stopPropagation()}
            role="none"
          >
            <div className="modal-content">
              {title && (
                <div className="modal-header">
                  <h5 className="modal-title">{title}</h5>
                  {toggleText && toggleHandler && (
                    <div className={styles.switcherWrapper}>
                      <Switcher prefix="modal_" onChange={toggleHandler} lable={toggleText} />
                    </div>
                  )}
                  <button
                    onClick={onClose}
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                  />
                </div>
              )}
              {children}
            </div>
          </div>
        </div>,
        document.getElementById('modal-root') as HTMLDivElement,
      )}
    </>
  );
};

export default Modal;
