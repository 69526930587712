import { API, AUTH_TYPE } from './constants';
import { checkAndFormatAxiosError } from './error';
import { apiInstance } from './index';

type GetUserAuthTypeResponse = {
  authType: string;
};

export async function getUserAuthType(username: string): Promise<AUTH_TYPE> {
  try {
    const { data } = await apiInstance.post<GetUserAuthTypeResponse>(
      API.GET_USER_AUTH_TYPE,
      { username },
    );
    const { authType } = data;
    if (authType === 'sso') {
      return AUTH_TYPE.SSO;
    }
    if (authType === 'password') {
      return AUTH_TYPE.PASSWORD;
    }
    throw new Error(`Wrong auth type ${authType}`);
  } catch (error) {
    throw checkAndFormatAxiosError(error);
  }
}

export type GetUserAuthPayload = {
  authType: AUTH_TYPE.PASSWORD;
  username: string;
  password: string;
} | {
  authType: AUTH_TYPE.SSO;
  code: string;
};

type GetUserExternalSessionResponse = {
  sessionToken: string;
};

export async function getUserExternalSession(
  payload: GetUserAuthPayload,
): Promise<string> {
  try {
    const { data } = await apiInstance.post<GetUserExternalSessionResponse>(
      API.AUTHORIZE_VEEVA_SESSION,
      payload,
    );

    return data.sessionToken;
  } catch (error) {
    throw checkAndFormatAxiosError(error);
  }
}
