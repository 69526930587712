import _ from 'lodash';
import React, { useRef, useEffect, useMemo } from 'react';
import AccordionPanel from 'components/Accordion/AccordionPanel';
import LayoutPreviewList from 'components/LayoutPreviewList';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  toggleActivePanels,
  selectActivePanels,
  selectTemplateReplacedLayouts,
  selectScreens,
} from 'store/simulationSlice';
import type { Layout, ScreenToView } from 'types';
import BoundingBox from 'utils/BoundingBox';
import { eventEmitter, EVENTS } from 'utils/eventEmitter';

const getBoundingBox = (element: HTMLDivElement | null | undefined): BoundingBox => {
  const { top, height } = element?.getBoundingClientRect() ?? { top: 0, height: 0 };

  return new BoundingBox(top, top + height);
};

type PanelsWithItemsRef = Record<string, {
  panel: HTMLDivElement | null;
  items: Record<string, HTMLDivElement | null>;
}>;

interface AccordionProps {
  screens: ScreenToView[];
  scrollContainer?: React.RefObject<HTMLDivElement>;
  onClearButtonClick: (screen: ScreenToView) => void;
  onItemClick?: (screen: ScreenToView, layout: Layout) => void;
}

const Accordion: React.FC<AccordionProps> = ({
  screens,
  scrollContainer,
  onClearButtonClick,
  onItemClick = Function.prototype,
}) => {
  const activePanels = useAppSelector(selectActivePanels);
  const templateReplacedLayouts = useAppSelector(selectTemplateReplacedLayouts);
  const templateScreens = useAppSelector(selectScreens);
  const templateLayouts = useMemo(
    () => _(templateScreens).flatMap('sections').flatMap<Layout>('layouts').value(), [templateScreens]);
  const dispatch = useAppDispatch();

  const panelsWithItems = useRef<PanelsWithItemsRef>({});

  useEffect(() => {
    const expandPanel = (screenId: string, layoutId?: string): void => {
      dispatch(toggleActivePanels({ screenId, activeState: true }));
      setTimeout(() => {
        const data = panelsWithItems.current?.[screenId];
        const panel = data?.panel;
        if (!panel) {
          return;
        }

        const item = layoutId && data?.items?.[layoutId];
        if (!item) {
          return panel.scrollIntoView({ behavior: 'smooth' });
        }

        const containerRect = getBoundingBox(scrollContainer?.current);
        const itemRect = getBoundingBox(item);
        if (containerRect.contains(itemRect)) {
          return;
        }

        const panelRect = getBoundingBox(panel);
        const shift = containerRect.top - panelRect.top;
        if (shift > 0 && containerRect.contains(itemRect.shift(shift))) {
          panel.scrollIntoView({ behavior: 'smooth' });
        } else {
          item.scrollIntoView({ behavior: 'smooth' });
        }
      });
    };

    eventEmitter.addListener(EVENTS.EXPAND_ACCORDION, expandPanel);

    return () => {
      eventEmitter.removeListener(EVENTS.EXPAND_ACCORDION, expandPanel);
    };
  }, []);

  const onClickPanel = (sectionId: string, screenId: string, activeState?: boolean): void => {
    dispatch(toggleActivePanels({ screenId, activeState }));
    eventEmitter.emit(EVENTS.SCROLL_SECTION_RIGHT, sectionId);
  };

  const getCurrentSectionId = (sectionIds: string[]): string => {
    const relatedSectionId = sectionIds.find(sectionId => templateReplacedLayouts[sectionId]);

    return relatedSectionId ?? sectionIds[0];
  };

  const isClearButtonShown = (layouts: Layout[]): boolean => {
    return templateLayouts.some(templateLayout => layouts.some(layout => templateLayout.id === layout.id));
  };

  return (
    <>
      {_.map(screens, screen => (
        <AccordionPanel
          key={screen.id}
          ref={(panel): void => {
            panelsWithItems.current[screen.id] = panelsWithItems.current[screen.id] || {};
            panelsWithItems.current[screen.id].panel = panel;
          }}
          sectionId={screen.sectionIds[0]}
          isActive={activePanels[screen.id]}
          isClearButtonShown={isClearButtonShown(screen.layouts)}
          label={screen.displayName}
          onClick={(): void => onClickPanel(getCurrentSectionId(screen.sectionIds), screen.id)}
          onClearButtonClick={(): void => onClearButtonClick(screen)}
        >
          <LayoutPreviewList
            ref={(items): void => {
              panelsWithItems.current[screen.id] = panelsWithItems.current[screen.id] || {};
              panelsWithItems.current[screen.id].items = items ?? {};
            }}
            layouts={screen.layouts}
            onItemClick={onItemClick.bind(this, screen)}
          />
        </AccordionPanel>
      ))}
    </>
  );
};

export default Accordion;
