import type { ModuleTemplate, ModuleBundleActionPayload } from 'types';

const URL_KEYS = {
  TEMPLATE: 'template',
  BUNDLE: 'bundle',
};

interface URLChannelParams {
  template: ModuleTemplate;
  bundle: ModuleBundleActionPayload;
}

interface DocumentQueryData {
  documentId: string | number;
  majorVersion: string | number;
  minorVersion: string | number;
}

export const getDocumentQueryString = ({
  documentId,
  majorVersion,
  minorVersion,
}: DocumentQueryData): string => {
  return `${documentId}_${majorVersion}_${minorVersion}`;
};
export const getDocumentFromQueryString = (query: string | null): DocumentQueryData => {
  if (!query) {
    throw new Error('splitDocumentQuery: Empty query string');
  }

  const [documentId, majorVersion, minorVersion] = query.split('_');

  return { documentId, majorVersion, minorVersion };
};

export const makeQuery = ({ template, bundle }: URLChannelParams): string => {
  const urlParams = new URLSearchParams();

  urlParams.append(URL_KEYS.BUNDLE, getDocumentQueryString(bundle));
  urlParams.append(URL_KEYS.TEMPLATE, getDocumentQueryString(template));

  return urlParams.toString();
};

export const parseQuery = (params: string): {
  template: DocumentQueryData;
  moduleBundle: DocumentQueryData;
} => {
  const urlParams = new URLSearchParams(params);

  return {
    template: getDocumentFromQueryString(urlParams.get(URL_KEYS.TEMPLATE)),
    moduleBundle: getDocumentFromQueryString(urlParams.get(URL_KEYS.BUNDLE)),
  };
};
