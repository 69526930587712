
export default class BoundingBox {
  constructor(readonly top: number = 0, readonly bottom: number = 0) {}

  shift(delta: number): BoundingBox {
    return new BoundingBox(this.top + delta, this.bottom + delta);
  }

  contains(other: BoundingBox): boolean {
    return this.top < other.top && this.bottom > other.bottom;
  }
}
