import { call, put, takeLatest } from 'redux-saga/effects';
import { AUTH_TYPE } from 'api/constants';
import { GetUserAuthPayload, getUserAuthType, getUserExternalSession } from 'api/getUser';
import { setSessionId } from 'utils';
import {
  signInDiscoveryError,
  signInDiscoveryRequest,
  signInDiscoveryStart,
  signInDiscoverySuccess,
  signInError,
  signInRequest,
  signInStart,
  signInSuccess,
} from './authSlice';
import { userSaga } from './userSaga';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';

function* authSaga({ payload }: PayloadAction<GetUserAuthPayload>): SagaIterator {
  try {
    yield put(signInStart());
    const sessionToken: string = yield call(getUserExternalSession, payload);

    yield call(setSessionId, sessionToken);
    yield call(userSaga);
    yield put(signInSuccess());
    yield put(signInDiscoverySuccess(AUTH_TYPE.SSO));
  } catch (err) {
    yield put(signInError(err instanceof Error ? err.message : JSON.stringify(err)));
  }
}

function* authDiscoverySaga({ payload }: PayloadAction<string>): SagaIterator {
  try {
    yield put(signInDiscoveryStart());
    const authType: AUTH_TYPE = yield call(getUserAuthType, payload);
    yield put(signInDiscoverySuccess(authType));
  } catch (err) {
    yield put(signInDiscoveryError(err instanceof Error ? err.message : JSON.stringify(err)));
  }
}

export default [
  takeLatest(signInRequest.type, authSaga),
  takeLatest(signInDiscoveryRequest.type, authDiscoverySaga),
];
