import React, { useEffect, useMemo, useRef } from 'react';
import styles from '../Combinations.module.scss';
import { CombinationSparseArray } from '../useCombinations';

type Props = {
  combinations: CombinationSparseArray[];
  selectedIndexes: number[];
  onChangeSelection: (arg: number[] | ((list: number[]) => number[])) => void;
};

const CombinationsListToggle: React.FC<Props> = (props) => {
  const { combinations, selectedIndexes, onChangeSelection } = props;

  const combinationsIndexes = useMemo(
    () => combinations.flatMap((item, idx) => item ? [idx] : []),
    [combinations],
  );
  const totalNumber = combinationsIndexes.length;
  const selectedNumber = selectedIndexes.length;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.indeterminate = totalNumber > 0 && selectedNumber > 0 && selectedNumber < totalNumber;
  }, [totalNumber, selectedNumber]);

  const onClick = (): void => onChangeSelection((list) => {
    if (combinationsIndexes.every(idx => list.includes(idx))) {
      return [];
    } else {
      return [...combinationsIndexes];
    }
  });

  return (
    <div className={styles.container__left__toggleall}>
      <input
        ref={inputRef}
        className="form-check-input"
        type="checkbox"
        onChange={onClick}
        checked={totalNumber === selectedNumber}
      />
      <span>
        {`${selectedNumber}/${totalNumber} combinations`}
      </span>
    </div>
  );
};

export default CombinationsListToggle;
