// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LayoutPreview_layoutPreviewWrapper__ShemX {
  position: relative;
}

.LayoutPreview_clipboardWrapper__sh1b4 {
  background-color: white;
  position: absolute;
  top: 0;
  right: -156px;
  padding: 4px 10px 6px;
  box-shadow: 0px 1px 1px 0px rgba(142, 142, 142, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/components/LayoutPreview/LayoutPreview.module.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ;;AAIA;EACI,uBAAA;EACA,kBAAA;EACA,MAAA;EACA,aAVgB;EAWhB,qBAAA;EACA,qDAAA;AADJ","sourcesContent":["$minWidthAssetName: -156px;\n\n.layoutPreviewWrapper {\n    position: relative;\n}\n\n.clipboardWrapper {\n    background-color: white;\n    position: absolute;\n    top: 0;\n    right: $minWidthAssetName;\n    padding: 4px 10px 6px;\n    box-shadow: 0px 1px 1px 0px rgba(142, 142, 142, 0.25);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layoutPreviewWrapper": `LayoutPreview_layoutPreviewWrapper__ShemX`,
	"clipboardWrapper": `LayoutPreview_clipboardWrapper__sh1b4`
};
export default ___CSS_LOADER_EXPORT___;
