import React from 'react';
import ModuleBundleDocumentLink from 'components/ModuleBundleDocumentLink';
import { ModuleBundleType } from 'types';
import styles from '../styles.module.scss';

type Props = {
  moduleBundle: ModuleBundleType;
  majorVersion: number;
  minorVersion: number;
};

const Header: React.FC<Props> = (props) => {
  const { moduleBundle, majorVersion, minorVersion } = props;

  const veevaUrl = moduleBundle?.veevaUrl;
  const moduleBundleName = moduleBundle?.name
    ? `${moduleBundle.name} (v${majorVersion}.${minorVersion})`
    : '';

  return (
    <div className={styles.contentContainer}>
      <h4 className="p-0 pb-3 m-0" style={{ width: 'auto' }}>
        Select the channel to see the simulation
      </h4>
      <div className="border-bottom pb-3">
        <ModuleBundleDocumentLink
          url={veevaUrl}
          linkText={moduleBundleName}
        />
      </div>
    </div>
  );
};

export default Header;
