import classNames from 'classnames';
import React from 'react';

import LinkIcon from 'assets/images/LinkIcon';

import styles from './styles.module.scss';

interface ModuleBundleDocumentLinkProps {
  linkText: string;
  url: string;
  headerClassName?: boolean;
}

const ModuleBundleDocumentLink: React.FC<ModuleBundleDocumentLinkProps> = ({
  headerClassName,
  url,
  linkText,
}) => {
  if (!linkText) {
    return null;
  }

  return (
    <div className={classNames(styles.linkWrapper, {
      [styles.moduleBundleHeader]: headerClassName,
    })}>
      <div>
        <span className={styles.label}>Module Bundle:</span>
        <a className={styles.link} href={url} target="_blank" rel="noreferrer">
          {linkText}
          <LinkIcon />
        </a>
      </div>
    </div>
  );
};

export default ModuleBundleDocumentLink;
