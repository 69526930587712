import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AUTH_TYPE } from 'api/constants';
import { GetUserAuthPayload } from 'api/getUser';
import { SAGA_STATUS } from 'const';
import type { RootState } from './index';

interface AuthState {
  isAuthenticated: boolean;
  authType: AUTH_TYPE | undefined;
  status: SAGA_STATUS;
  error: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  authType: undefined,
  status: SAGA_STATUS.IDLE,
  error: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInDiscoveryStart: (state) => {
      state.status = SAGA_STATUS.PENDING;
      state.error = '';
    },
    signInDiscoverySuccess: (state, action: PayloadAction<AUTH_TYPE>) => {
      state.authType = action.payload;
      state.status = SAGA_STATUS.IDLE;
    },
    signInDiscoveryError: (state, action: PayloadAction<string>) => {
      state.status = SAGA_STATUS.IDLE;
      state.error = action.payload;
    },
    signInStart: (state) => {
      state.status = SAGA_STATUS.PENDING;
      state.error = '';
    },
    signInSuccess: (state) => {
      state.isAuthenticated = true;
      state.status = SAGA_STATUS.IDLE;
    },
    signInFailure: (state) => {
      state.isAuthenticated = false;
      state.status = SAGA_STATUS.IDLE;
    },
    signInError: (state, action: PayloadAction<string>) => {
      state.status = SAGA_STATUS.IDLE;
      state.error = action.payload;
    },
    resetAuth: () => initialState,
  },
});

export const signInRequest = createAction<GetUserAuthPayload>('signInRequest');
export const signInDiscoveryRequest = createAction<string>(
  'signInDiscoveryRequest',
);

export const selectIsAuthenticated = (state: RootState): boolean =>
  state.auth.isAuthenticated;

export const selectAuthType = (state: RootState): AUTH_TYPE | undefined => state.auth.authType;

export const selectIsAuthInProgress = (state: RootState): boolean =>
  state.auth.status === SAGA_STATUS.PENDING;

export const selectAuthError = (state: RootState): string => state.auth.error;

export const {
  signInDiscoveryStart,
  signInDiscoverySuccess,
  signInDiscoveryError,
  signInStart,
  signInSuccess,
  signInFailure,
  signInError,
  resetAuth,
} = authSlice.actions;

export default authSlice.reducer;
