import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SAGA_STATUS } from 'const';
import type {
  User,
  UserContact,
  UsersData,
} from 'types';
import type { RootState } from './index';

interface UserState {
  user: User;
  usersContacts: UserContact[];
  status: SAGA_STATUS;
  error: string;
}

const initialState: UserState = {
  user: {
    firstName: '',
    lastName: '',
    userName: '',
    userEmail: '',
  },
  usersContacts: [],
  status: SAGA_STATUS.IDLE,
  error: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserDataStart: (state) => {
      state.status = SAGA_STATUS.PENDING;
      state.error = '';
    },
    getUserDataSuccess: (state, action: PayloadAction<UsersData>) => {
      state.status = SAGA_STATUS.IDLE;
      state.user = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        userName: action.payload.userName,
        userEmail: action.payload.userEmail,
      };
      state.usersContacts = action.payload.usersContacts;
    },
    getUserDataError: (state, action: PayloadAction<string>) => {
      state.status = SAGA_STATUS.IDLE;
      state.error = action.payload;
    },
  },
});

export const getUserDataRequest = createAction('getUserDataRequest');

export const selectUserDataError = (state: RootState): string =>
  state.user.error;

export const selectIsUserDataFetchInProgress = (state: RootState): boolean =>
  state.user.status === SAGA_STATUS.PENDING;

export const selectFullUserName = (state: RootState): string => {
  const firstName = state.user.user.firstName;
  const lastName = state.user.user.lastName;

  if (firstName || lastName) {
    return `${firstName} ${lastName}`;
  }

  return '';
};

export const selectOwnerEmail =
  (ownerName: string) =>
    (state: RootState): string =>
      (
        state.user.usersContacts.find(
          userContact => userContact.name === ownerName,
        ) as UserContact
      ).email;

export const selectCurrentUserName = (state: RootState): string =>
  state.user.user.userName;

export const selectCurrentUserEmail = (state: RootState): string =>
  state.user.user.userEmail;

export const selectUserNames = (state: RootState): string[] =>
  state.user.usersContacts.map(userContact => userContact.name);

export const { getUserDataStart, getUserDataSuccess, getUserDataError } = userSlice.actions;
export default userSlice.reducer;
