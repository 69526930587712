import { inflect } from 'inflection';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { ModulesBundle, ModulesSection } from 'store/modules/types';
import { buildCombinations } from './utils';

export type CombinationItem = {
  index: number; // number of module in bundle group
  bundleIdx: number;
  moduleIdx: number;
};

export type CombinationItemWithGroup = {
  index: number; // number of module in bundle group
  bundleIdx: number;
  groupIdx: number;
  modules: number[];
};

// sparse array
export type CombinationSparseArray = (CombinationItem | CombinationItemWithGroup)[];

export function isCombinationItemWithGroup(
  item: CombinationItem | CombinationItemWithGroup,
): item is CombinationItemWithGroup {
  return (item as CombinationItemWithGroup).groupIdx !== undefined;
}

const toastDanger = (msg: string): void => {
  toast(msg, {
    hideProgressBar: true,
    style: { backgroundColor: '#da3737' },
  });
};

const toastForCombinationsNumberChange = (delta: number): void => {
  if (delta === 0) {
    return;
  }
  if (delta > 0) {
    toast(
      `${delta} ${inflect('combination', delta)} ${inflect('was', delta, 'was', 'were')} added`,
      { hideProgressBar: true },
    );
  } else {
    const count = Math.abs(delta);
    toastDanger(
      `${count} ${inflect('combination', count)} ${inflect('was', count, 'was', 'were')} removed`,
    );
  }
};

const countCombinations = (combinations: CombinationSparseArray[]): number => combinations.filter(i => i).length;

type UseCombinationsReturnType = {
  combinations: CombinationSparseArray[];
  combinationsNumber: number;
  combinationsPossibleNumber: number;
  removeCombinations: (indexes: number[], showToast?: boolean) => void;
};

export function useCombinations(
  sections: ModulesSection[] | undefined,
  bundles: ModulesBundle[] | undefined,
) : UseCombinationsReturnType {

  const [combinations, setCombinations] = useState<CombinationSparseArray[]>([]);
  const combinationsRef = useRef(combinations); // to show toast

  const combinationsNumber = useMemo(
    () => countCombinations(combinations),
    [combinations],
  );

  const removeCombinations = useCallback((indexes: number[], showToast = true) => {
    setCombinations((sparseArray) => {
      const newList = sparseArray.map(item => item);
      indexes.forEach((idx) => {
        delete newList[idx]; // nosonar
      });
      combinationsRef.current = newList; // to skip toast

      return newList;
    });
    if (showToast) {
      if (indexes.length > 1) {
        toastForCombinationsNumberChange(-indexes.length);
      } else {
        toastDanger(`Combination ${indexes[0] + 1} was removed`);
      }
    }
  }, []);

  const combinationsPossibleNumber = useMemo(
    () => bundles && sections ? buildCombinations(bundles, sections, false).length : 0,
    [bundles, sections],
  );

  useEffect(() => {
    if (!bundles || !sections) {
      return;
    }
    setCombinations(buildCombinations(bundles, sections));
  }, [bundles, sections]);

  useEffect(() => {
    if (combinationsRef.current === combinations) {
      return;
    }
    const delta = combinationsNumber - countCombinations(combinationsRef.current);
    toastForCombinationsNumberChange(delta);

    combinationsRef.current = combinations;
  }, [combinations]); // combinationsNumber intentionally skiped from dependencies

  return {
    combinations,
    combinationsNumber,
    combinationsPossibleNumber,
    removeCombinations,
  };
}
