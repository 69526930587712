import { call, put, takeLatest } from 'redux-saga/effects';
import { getModuleBundleChannels } from 'api/getModuleBundleChannels';
import type { ModuleBundleActionPayload } from 'types';
import { getSessionId } from 'utils';
import {
  getModuleBundleChannelsDataRequest,
  getModuleBundleChannelsDataSuccess,
  getModuleBundleChannelsDataError,
  getModuleBundleChannelsDataStart,
} from './channelsSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SagaIterator } from 'redux-saga';

function* channelsSaga({
  payload,
}: PayloadAction<ModuleBundleActionPayload>): SagaIterator {
  try {
    yield put(getModuleBundleChannelsDataStart());
    const sessionId: string = yield call(getSessionId);

    const moduleBundle = yield call(getModuleBundleChannels, sessionId, payload);

    yield put(getModuleBundleChannelsDataSuccess(moduleBundle));
  } catch (err) {
    yield put(getModuleBundleChannelsDataError(err instanceof Error ? err.message : JSON.stringify(err)));
  }
}

export default [
  takeLatest(getModuleBundleChannelsDataRequest.type, channelsSaga),
];
