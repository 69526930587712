/* eslint-disable multiline-ternary */
import React from 'react';
import ExclamationBadge from 'assets/images/ExclamationBadge';
import styles from './Tab.module.scss';

interface TabProps {
  count: number;
  index: number;
  isActive: boolean;
  isShowWarning?: boolean;
  label: string;
  testId?: string;
  onClick: (evt: React.MouseEvent) => void;
}

const Tab: React.FC<TabProps> = ({
  count,
  index,
  isActive,
  isShowWarning = false,
  label,
  testId = '',
  onClick,
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    <li className="nav-item" data-testid={testId}>
      <button
        className={`d-flex justify-content-between align-items-center px-3 py-2 mb-2 ${
          isActive ? styles.active : ''
        } ${styles.button}`}
        type="button"
        style={{ width: '100%' }}
        onClick={onClick}
        data-index={index}
      >
        {label}
        {count ? (
          <span className="badge rounded-5 text-bg-light">{count}</span>
        ) : null}
        {isShowWarning ? (
          <span className="mx-1">
            <ExclamationBadge />
          </span>
        ) : null}
      </button>
    </li>
  );
};

export default Tab;
