// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Switcher_switcher__0NEyx {
  position: relative;
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
}
.Switcher_switcher__0NEyx input {
  opacity: 0;
  height: 0;
  width: 0;
  border-radius: 0;
}
.Switcher_switcherSlider__G9cpU {
  background: #CED0DB;
  border-radius: 12px;
  position: relative;
  width: 24px;
  height: 12px;
  display: inline-block;
  padding: 1px;
}
.Switcher_switcherSlider__G9cpU::after {
  background-color: white;
  border-radius: 100%;
  height: 10px;
  width: 10px;
  content: "";
  position: absolute;
  top: 1px;
}
.Switcher_switcher__0NEyx input:checked + .Switcher_switcherSlider__G9cpU {
  background-color: #00857C;
}
.Switcher_switcher__0NEyx input:checked + .Switcher_switcherSlider__G9cpU::after {
  right: 1px;
  left: unset;
}
.Switcher_switcherTextLabel__zKMS0 {
  margin-left: 4px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
}
.Switcher_switcherWrapper__Kb\\+Ao {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Switcher/Switcher.module.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AADJ;AAGI;EACI,UAAA;EACA,SAAA;EACA,QAAA;EACA,gBAAA;AADR;AAII;EACI,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,YAAA;AAFR;AAIQ;EACI,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;AAFZ;AAMI;EACI,yBAAA;AAJR;AAMQ;EACI,UAAA;EACA,WAAA;AAJZ;AAQI;EACI,gBAAA;EACA,iBAAA;EAEI,eAAA;EACA,gBAAA;AAPZ;AAUI;EACI,aAAA;EACA,mBAAA;AARR","sourcesContent":["@import '../../styles/colors.module';\n\n.switcher {\n    position: relative;\n    cursor: pointer;\n    height: 24px;\n    display: flex;\n    align-items: center;\n\n    input {\n        opacity: 0;\n        height: 0;\n        width: 0;\n        border-radius: 0;\n    }\n\n    &Slider {\n        background: map-get($gray, 100);\n        border-radius: 12px;\n        position: relative;\n        width: 24px;\n        height: 12px;\n        display: inline-block;\n        padding: 1px;\n\n        &::after {\n            background-color: white;\n            border-radius: 100%;\n            height: 10px;\n            width: 10px;\n            content: '';\n            position: absolute;\n            top: 1px;\n        }\n    }\n\n    input:checked + .switcherSlider {\n        background-color: map-get($green, 300);\n\n        &::after {\n            right: 1px;\n            left: unset;\n        }\n    }\n\n    &TextLabel {\n        margin-left: 4px;\n        line-height: 24px;\n        font: {\n            size: 14px;\n            weight: 500;\n        }\n    }\n    &Wrapper {\n        display: flex;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switcher": `Switcher_switcher__0NEyx`,
	"switcherSlider": `Switcher_switcherSlider__G9cpU`,
	"switcherTextLabel": `Switcher_switcherTextLabel__zKMS0`,
	"switcherWrapper": `Switcher_switcherWrapper__Kb+Ao`
};
export default ___CSS_LOADER_EXPORT___;
