/* eslint-disable max-len */

import React from 'react';

const Find: React.FC = () => {
  return (
    <svg
      width="81"
      height="81"
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40.5" cy="40.5" r="40" fill="#F8F9FA" />
      <circle cx="40.5" cy="40.5" r="28" fill="#E9ECEF" />
      <g clipPath="url(#clip0_129_69453)">
        <path
          d="M46.113 44.516C47.5654 42.5341 48.216 40.0768 47.9345 37.6358C47.6529 35.1947 46.4601 32.95 44.5946 31.3507C42.7292 29.7514 40.3286 28.9154 37.8733 29.01C35.4179 29.1047 33.0888 30.1229 31.3519 31.861C29.615 33.5991 28.5985 35.9289 28.5056 38.3844C28.4128 40.8398 29.2505 43.2398 30.8511 45.1041C32.4518 46.9684 34.6973 48.1596 37.1385 48.4394C39.5797 48.7192 42.0366 48.0669 44.0175 46.613H44.016C44.061 46.673 44.109 46.73 44.163 46.7855L49.938 52.5605C50.2193 52.842 50.6008 53.0002 50.9987 53.0003C51.3966 53.0005 51.7783 52.8425 52.0598 52.5613C52.3412 52.28 52.4994 51.8985 52.4996 51.5006C52.4997 51.1027 52.3418 50.721 52.0605 50.4395L46.2855 44.6645C46.2319 44.6102 46.1742 44.5601 46.113 44.5145V44.516ZM46.5 38.75C46.5 39.8334 46.2866 40.9062 45.872 41.9072C45.4574 42.9081 44.8497 43.8176 44.0836 44.5837C43.3176 45.3497 42.4081 45.9574 41.4071 46.372C40.4062 46.7866 39.3334 47 38.25 47C37.1666 47 36.0938 46.7866 35.0929 46.372C34.0919 45.9574 33.1825 45.3497 32.4164 44.5837C31.6503 43.8176 31.0426 42.9081 30.628 41.9072C30.2134 40.9062 30 39.8334 30 38.75C30 36.562 30.8692 34.4636 32.4164 32.9164C33.9635 31.3692 36.062 30.5 38.25 30.5C40.438 30.5 42.5365 31.3692 44.0836 32.9164C45.6308 34.4636 46.5 36.562 46.5 38.75V38.75Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_129_69453">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(28.5 29)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Find;
