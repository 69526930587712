/* eslint-disable max-len */

import React from 'react';

const ArrowRight: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_112_131407)">
        <rect width="16" height="16" fill="white" fillOpacity="0.01" />
        <path
          className="svg-arrow-path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.999756 7.99917C0.999756 7.86656 1.05243 7.73938 1.1462 7.64561C1.23997 7.55185 1.36715 7.49917 1.49976 7.49917H13.2928L10.1458 4.35317C10.0519 4.25928 9.99912 4.13194 9.99912 3.99917C9.99912 3.86639 10.0519 3.73905 10.1458 3.64517C10.2396 3.55128 10.367 3.49854 10.4998 3.49854C10.6325 3.49854 10.7599 3.55128 10.8538 3.64517L14.8538 7.64517C14.9003 7.69161 14.9373 7.74679 14.9625 7.80753C14.9877 7.86828 15.0006 7.9334 15.0006 7.99917C15.0006 8.06493 14.9877 8.13005 14.9625 8.1908C14.9373 8.25154 14.9003 8.30672 14.8538 8.35317L10.8538 12.3532C10.7599 12.4471 10.6325 12.4998 10.4998 12.4998C10.367 12.4998 10.2396 12.4471 10.1458 12.3532C10.0519 12.2593 9.99912 12.1319 9.99912 11.9992C9.99912 11.8664 10.0519 11.7391 10.1458 11.6452L13.2928 8.49917H1.49976C1.36715 8.49917 1.23997 8.44649 1.1462 8.35272C1.05243 8.25895 0.999756 8.13177 0.999756 7.99917Z"
          fill="#00857C"
        />
      </g>
      <defs>
        <clipPath id="clip0_112_131407">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowRight;
