/* eslint-disable max-len */

import React from 'react';

const RightAngleArrow: React.FC = () => {
  return (
    <svg viewBox="0 0 20 20">
      <g>
        <path fill="#212529" d="M5.80666 17.9428C5.74846 17.8848 5.70228 17.8158 5.67077 17.7399C5.63926 17.664 5.62305 17.5826 5.62305 17.5003C5.62305 17.4181 5.63926 17.3367 5.67077 17.2608C5.70228 17.1849 5.74846 17.1159 5.80666 17.0578L12.8654 10.0003L5.80666 2.94285C5.74855 2.88474 5.70246 2.81575 5.67101 2.73983C5.63956 2.6639 5.62337 2.58253 5.62337 2.50035C5.62337 2.41817 5.63956 2.33679 5.67101 2.26087C5.70246 2.18494 5.74855 2.11596 5.80666 2.05785C5.86477 1.99974 5.93376 1.95364 6.00968 1.92219C6.08561 1.89075 6.16698 1.87456 6.24916 1.87456C6.33134 1.87456 6.41272 1.89075 6.48864 1.92219C6.56457 1.95364 6.63355 1.99974 6.69166 2.05785L14.1917 9.55785C14.2499 9.6159 14.296 9.68487 14.3276 9.76081C14.3591 9.83674 14.3753 9.91814 14.3753 10.0003C14.3753 10.0826 14.3591 10.164 14.3276 10.2399C14.296 10.3158 14.2499 10.3848 14.1917 10.4428L6.69166 17.9428C6.63361 18.0011 6.56464 18.0472 6.48871 18.0787C6.41277 18.1102 6.33137 18.1265 6.24916 18.1265C6.16695 18.1265 6.08555 18.1102 6.00962 18.0787C5.93369 18.0472 5.86472 18.0011 5.80666 17.9428Z"/>
      </g>
    </svg>
  );
};

export default RightAngleArrow;
