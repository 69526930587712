import _ from 'lodash';
import React, { useEffect } from 'react';
import variables from 'styles/_variables.module.scss';
import NoModules from 'assets/images/NoModules';
import TemplateNoLayouts from 'assets/images/TemplateNoLayouts';
import Button from 'components/Button/Button';
import ModuleBundleDocumentLink from 'components/ModuleBundleDocumentLink';
import NavBar from 'components/NavBar/NavBar';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearStorageState } from 'store/persistentState';
import {
  setMergedSimulationCombinations,
  getModuleBundleTemplateDataRequest,
  getTemplateDataRequest,
  selectModuleBundle,
  selectSimulationDataLoadingStatus,
  selectMergedSimulationCombinations,
  selectTemplate,
  selectTemplateHasLayouts,
  removeAllLayouts,
  areSearchParamsSame,
  setSearchString,
  resetSimulationState,
  selectErrorTemplateData,
  selectErrorModuleBundleData,
  selectTemplateReplacedLayouts,
} from 'store/simulationSlice';
import type { TemplateSection } from 'types';
import { getDocumentFromQueryString, mergeSimulationCombinations, sortScreensByTemplates } from 'utils';
import Disclaimer from './components/Disclaimer';
import FullPageLoading from './components/FullPageLoading';
import SimulationData from './components/SimulationData';
import SimulationErrorPage from './components/SimulationErrorPage';
import styles from './styles.module.scss';

const Simulation: React.FC = () => {
  const searchString = window.location.search;
  const searchParams = new URLSearchParams(searchString);
  const moduleBundleQuery = getDocumentFromQueryString(searchParams.get('bundle'));
  const templateQuery = getDocumentFromQueryString(searchParams.get('template'));
  const dispatch = useAppDispatch();
  const moduleBundle = useAppSelector(selectModuleBundle);
  const template = useAppSelector(selectTemplate);
  const shouldUpdateData = !useAppSelector(areSearchParamsSame(searchString));
  const errorTemplateData = useAppSelector(selectErrorTemplateData);
  const errorModuleBundleData = useAppSelector(selectErrorModuleBundleData);
  const templateHasLayouts = useAppSelector(selectTemplateHasLayouts);
  const templateReplacedLayouts = useAppSelector(selectTemplateReplacedLayouts);
  const isClearAllButtonDisabled = Object.keys(templateReplacedLayouts).length === 0;

  useEffect(() => {
    if (shouldUpdateData) {
      dispatch(resetSimulationState());
      dispatch(setSearchString(searchString));
    }

    if (shouldUpdateData || errorTemplateData) {
      dispatch(getTemplateDataRequest({
        documentId: Number(templateQuery.documentId),
        majorVersion: Number(templateQuery.majorVersion),
        minorVersion: Number(templateQuery.minorVersion),
      }));
    }

    if (shouldUpdateData || errorModuleBundleData) {
      dispatch(getModuleBundleTemplateDataRequest({
        documentId: Number(moduleBundleQuery.documentId),
        majorVersion: Number(moduleBundleQuery.majorVersion),
        minorVersion: Number(moduleBundleQuery.minorVersion),
      }));
    }
  }, [shouldUpdateData]);

  const moduleBundleLeftPanel = useAppSelector(selectMergedSimulationCombinations);
  const isEmptyModuleBundle = Boolean(errorModuleBundleData) || moduleBundleLeftPanel.length === 0;

  useEffect(() => {
    if (moduleBundleLeftPanel.length === 0) {
      const temlateSections = _(template.screens).flatMap('sections').value() as TemplateSection[];
      const simulationCombinations = mergeSimulationCombinations(moduleBundle, temlateSections);
      const sortedSimulationCombinations = sortScreensByTemplates(simulationCombinations, temlateSections);
      dispatch(setMergedSimulationCombinations(sortedSimulationCombinations));
    }
  }, [moduleBundle, template]);

  const isSimulationDataLoading = useAppSelector(selectSimulationDataLoadingStatus);

  const moduleBundleName = moduleBundle?.name
    ? `${moduleBundle.name} (v${moduleBundleQuery.majorVersion}.${moduleBundleQuery.minorVersion})`
    : '';

  const handleClearAllButtonClick = (): void => {
    dispatch(removeAllLayouts());
    clearStorageState();
    dispatch(resetSimulationState());
    dispatch(getModuleBundleTemplateDataRequest({
      documentId: Number(moduleBundleQuery.documentId),
      majorVersion: Number(moduleBundleQuery.majorVersion),
      minorVersion: Number(moduleBundleQuery.minorVersion),
    }));
    dispatch(getTemplateDataRequest({
      documentId: Number(templateQuery.documentId),
      majorVersion: Number(templateQuery.majorVersion),
      minorVersion: Number(templateQuery.minorVersion),
    }));
  };

  const renderSwitch = (
    isModuleBundleEmpty: boolean,
    hasLayouts: boolean,
    loading: boolean,
  ): React.JSX.Element => {
    if (loading) {
      return <FullPageLoading />;
    } else if (!hasLayouts) {
      return (
        <SimulationErrorPage text="There are no reusable layouts in the selected channel">
          <TemplateNoLayouts />
        </SimulationErrorPage>
      );
    } else if (isModuleBundleEmpty) {
      return (
        <SimulationErrorPage text="There are no modules in the module bundle">
          <NoModules />
        </SimulationErrorPage>
      );
    } else {
      return (
        <>
          <Disclaimer />
          <SimulationData
            template={template}
            moduleBundleLeftPanel={moduleBundleLeftPanel}
          />
        </>
      );
    }
  };

  return (
    <main className={styles.main}>
      <NavBar isModuleCombinationLabelShown={true} discardPositionFixed={true}>
        <div className='w-100 d-flex align-items-center justify-content-between'>
          <ModuleBundleDocumentLink
            headerClassName={true}
            url={moduleBundle?.veevaUrl}
            linkText={moduleBundleName}
          />
          {templateHasLayouts && !isEmptyModuleBundle && (
            <div className="ps-3">
              <Button
                type='button'
                isDisabled={isClearAllButtonDisabled}
                label='Clear All'
                styleOverride={{
                  backgroundColor: isClearAllButtonDisabled
                    ? variables.clearAllButtonDisabled
                    : variables.clearAllButtonPrimary,
                  color: 'black',
                  marginBottom: 0,
                  padding: '5px 15px',
                  fontSize: variables.buttonFontSize,
                  borderRadius: variables.buttonBorderRadius,
                  whiteSpace: 'nowrap',
                }}
                onClick={handleClearAllButtonClick}
              />
            </div>
          )}
        </div>
      </NavBar>
      {renderSwitch(isEmptyModuleBundle, templateHasLayouts, isSimulationDataLoading)}
    </main>
  );
};

export default Simulation;
