import React, { useEffect, useRef, useState } from 'react';
import { selectSelectedFilters } from 'store/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getJobZeroRequest, selectJobZeroDataNextPage } from 'store/jobZeroSlice';
import { getSessionId } from 'utils';

type UseLoadNextPageReturnType = {
  observerTarget: React.MutableRefObject<HTMLTableRowElement | null>;
  isNextPageLoading: boolean;
};

export const useLoadNextPage = (shouldLoad: boolean, jobZeroDataLoading: boolean): UseLoadNextPageReturnType => {
  const dispatch = useAppDispatch();
  const sessionId = getSessionId();
  const selectedFilters = useAppSelector(selectSelectedFilters);
  const hasNextPage = Boolean(useAppSelector(selectJobZeroDataNextPage));
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const observerTarget = useRef(null);
  const fetchJobZeroDocuments = (): void => {
    setIsNextPageLoading(true);
    dispatch(
      getJobZeroRequest({
        sessionId,
        country: selectedFilters.selectedCountry,
        productFamily: selectedFilters.selectedProductFamily,
        resourceType: selectedFilters.selectedResourceType,
        deliveryMethod: selectedFilters.selectedDeliveryMethod,
        deploymentSupplier: selectedFilters.selectedDeploymentSupplier,
      }),
    );
  };

  useEffect(() => {
    if (!jobZeroDataLoading) {
      setIsNextPageLoading(false);
    }
  }, [jobZeroDataLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting
          && shouldLoad
          && !jobZeroDataLoading
          && hasNextPage
        ) {
          fetchJobZeroDocuments();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [
    observerTarget,
    selectedFilters,
    shouldLoad,
    jobZeroDataLoading,
    hasNextPage,
  ]);

  return {
    observerTarget,
    isNextPageLoading,
  };
};

