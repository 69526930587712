import React, { useId } from 'react';
import ClipboardText from 'components/Clipboard/ClipboardText';
import LayoutPreview from 'components/LayoutPreview';
import { ModulesBundle } from 'store/modules/types';
import styles from '../Modules.module.scss';

type Props = {
  module: ModulesBundle['modules'][number];
  onChange: () => void;
  checked: boolean | undefined;
  disabled?: boolean;
  showNumbers: boolean;
};

export const BundleListItemModule: React.FC<Props> = (props) => {
  const {
    module,
    onChange,
    checked,
    disabled,
    showNumbers,
  } = props;
  const htmlId = useId();
  const { number } = module;

  return (
    <label className={`mb-2 border rounded ${styles.bundlesListItem}`} htmlFor={htmlId}>
      <div className='d-flex align-items-center'>
        <label className="form-check-label title">
          <input
            className="form-check-input custom-check me-2"
            type="checkbox"
            value=""
            id={htmlId}
            onChange={onChange}
            checked={Boolean(checked)}
            disabled={disabled}
          />
          Asset #{module.index + 1}
        </label>

        {showNumbers && (<div className={styles.clipboardWrapper}><ClipboardText text={number} /></div>)}
      </div>
      <div className="content">
        <div className="content-row">
          <div className="content-controls" />
          <LayoutPreview documentId={module.id} alt={module.classification} />
        </div>
      </div>

    </label>
  );
};
