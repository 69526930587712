/* eslint-disable max-len */

import React from 'react';

const AlertTemplate: React.FC = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_661_223)">
        <rect width="80" height="80" fill="white" fillOpacity="0.01"/>
        <g clipPath="url(#clip1_661_223)">
          <path d="M9.99976 9.99915C9.99976 7.34698 11.0533 4.80344 12.9287 2.92808C14.8041 1.05271 17.3476 -0.000854492 19.9998 -0.000854492H59.9998C62.6519 -0.000854492 65.1955 1.05271 67.0708 2.92808C68.9462 4.80344 69.9998 7.34698 69.9998 9.99915V69.9991C69.9998 72.6513 68.9462 75.1948 67.0708 77.0702C65.1955 78.9456 62.6519 79.9991 59.9998 79.9991H19.9998C17.3476 79.9991 14.8041 78.9456 12.9287 77.0702C11.0533 75.1948 9.99976 72.6513 9.99976 69.9991V9.99915ZM44.9998 64.9991C44.9998 63.6731 44.473 62.4013 43.5353 61.4636C42.5976 60.5259 41.3258 59.9991 39.9998 59.9991C38.6737 59.9991 37.4019 60.5259 36.4642 61.4636C35.5265 62.4013 34.9998 63.6731 34.9998 64.9991C34.9998 66.3252 35.5265 67.597 36.4642 68.5347C37.4019 69.4724 38.6737 69.9991 39.9998 69.9991C41.3258 69.9991 42.5976 69.4724 43.5353 68.5347C44.473 67.597 44.9998 66.3252 44.9998 64.9991Z" fill="url(#paint0_linear_661_223)"/>
        </g>
      </g>
      <defs>
        <linearGradient id="paint0_linear_661_223" x1="39.9998" y1="-0.000854492" x2="39.9998" y2="79.9991" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00857C"/>
          <stop offset="1" stopColor="#29AAA1"/>
        </linearGradient>
        <clipPath id="clip0_661_223">
          <rect width="80" height="80" fill="white"/>
        </clipPath>
        <clipPath id="clip1_661_223">
          <rect width="80" height="80" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default AlertTemplate;
