import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-widgets/scss/styles.scss';
import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(container!);
// eslint-disable-next-line jest/require-hook
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
);
