import { Dispatch, createContext } from 'react';
import { ModulesBundleWithSelection } from '../hooks/helpers';
import { Action } from '../hooks/useBundles';

type BundlesContextType = {
  bundles: ModulesBundleWithSelection[];
  bundlesDispatch: Dispatch<Action>;
};

export const BundlesContext = createContext<BundlesContextType>({
  bundles: [],
  bundlesDispatch: () => undefined,
});
