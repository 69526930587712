import React from 'react';
import Find from 'assets/images/Find';

const InitialTableContent: React.FC = () => (
  <tr>
    <td className="border-0" colSpan={3}>
      <div className="d-flex flex-column align-items-center my-5">
        <Find />
        <p className="mt-3">Start filtering to find modular content</p>
      </div>
    </td>
  </tr>
);

export default InitialTableContent;
