import React, { PropsWithChildren, useEffect, useState } from 'react';
import IframePlaceholder from 'pages/Channels/IframePlaceholder';
import Home from 'pages/Home/Home';
import Loading from 'pages/Home/Loading';
import { selectIsAuthenticated, signInFailure } from 'store/authSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserDataRequest, selectIsUserDataFetchInProgress, selectUserDataError } from 'store/userSlice';
import { getIframeSessionId, getSessionId, removeSessionId } from 'utils/sessionStorage';

type Props = PropsWithChildren;

const isInIframe = window.self !== window.top;

if (isInIframe) {
  getIframeSessionId();
}

const AuthProvider = ({ children }: Props): React.JSX.Element => {
  const dispatch = useAppDispatch();

  const userError = useAppSelector(selectUserDataError);
  const userIsLoading = useAppSelector(selectIsUserDataFetchInProgress);

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  // sessionIdInState is used to trigger rerender after sessionId change
  const sessionId = getSessionId();
  const [sessionIdInState, setSessionIdInState] = useState(sessionId);

  useEffect(() => {
    if (sessionId === sessionIdInState) {
      return;
    }
    setSessionIdInState(sessionId);
  }, [sessionId, sessionIdInState]);

  useEffect(() => {
    if (!sessionId) {
      return;
    }

    if (userError) {
      removeSessionId();
      dispatch(signInFailure());
      setSessionIdInState('');
    } else {
      dispatch(getUserDataRequest());
    }

  }, [sessionId, userError, isAuthenticated]);

  if (isAuthenticated) {
    return <>{children}</>;
  }

  if (isInIframe) {
    return <IframePlaceholder />;
  }

  if (userIsLoading) {
    return <Loading />;
  }

  return <Home />;
};

export default AuthProvider;

