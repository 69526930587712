import { PayloadAction } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';
import { GetModulesDataResponse, getModulesData } from 'api/getModulesData';
import { getSessionId } from 'utils';
import { actions } from './slice';
import { ModulesActioinRequestPayload } from './types';
import type { SagaIterator } from 'redux-saga';

function* modulesSaga({ payload }: PayloadAction<ModulesActioinRequestPayload>): SagaIterator {
  const { jobZeroId, moduleBundleId } = payload;

  try {
    yield put(actions.getModulesStart());
    const sessionId: string = yield call(getSessionId);

    const data: GetModulesDataResponse = yield call(
      getModulesData,
      sessionId,
      { jobZeroId, moduleBundleId },
    );

    yield put(actions.getModulesSuccess(data));
  } catch (err) {
    yield put(actions.getModulesError(err instanceof Error ? err.message : JSON.stringify(err)));
  }
}

export default [takeLatest(actions.getModulesRequest.type, modulesSaga)];
