import _ from 'lodash';
import { DEFAULT_SECTION_WIDTH, ProjectsConfig, ProjectType } from 'const';
import type { PreviewOption } from 'types';

// unused since DCC-7735 - hardcoded 800px
export const getSectionWidth = (previewOptions: PreviewOption[], projectType: ProjectType): number => {
  const { defaultPreviewOption } = ProjectsConfig[projectType];
  let previewOption = _.find(previewOptions, { name: defaultPreviewOption });

  if (_.isEmpty(previewOption)) {
    previewOption = previewOptions[0];
  }

  return parseFloat(previewOption.width) || DEFAULT_SECTION_WIDTH;
};
