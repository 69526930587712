import { JobZeroDocumentData } from 'types';
import { API } from './constants';
import { checkAndFormatAxiosError } from './error';
import { apiInstance, getAuthorizationHeader } from './index';

export type GetJobZeroDocumentsFromVeevaPayload = {
  sessionId: string;
  productId: string;
  countryId: string;
  resourceId: string;
  nextPage: string | undefined;
  methodOfDelivery?: string;
  deploymentSupplierId?: string;
};

export async function getJobZeroDocumentsFromVeeva(
  payload: GetJobZeroDocumentsFromVeevaPayload,
): Promise<JobZeroDocumentData> {
  try {
    const { sessionId, ...body } = payload;

    const { data } = await apiInstance.post<JobZeroDocumentData>(
      API.GET_DOCUMENT_DATA_FROM_VEEVA,
      body,
      {
        headers: {
          ...getAuthorizationHeader(sessionId),
        },
      },
    );

    return {
      data: data.data,
      nextPage: data.nextPage,
    };
  } catch (error) {
    throw checkAndFormatAxiosError(error);
  }
}

