import { all, Effect } from 'redux-saga/effects';
import authSagas from './authSaga';
import channelsSaga from './channelsSaga';
import filtersSagas from './filtersSaga';
import jobZeroSagas from './jobZeroSaga';
import modulesSagas from './modules/saga';
import recipeSaga from './recipeSaga';
import simulationSaga from './simulationSaga';
import userSagas from './userSaga';
import type { SagaIterator } from 'redux-saga';

export default function* rootSaga(): SagaIterator {
  const effects = [
    ...authSagas,
    ...userSagas,
    ...filtersSagas,
    ...jobZeroSagas,
    ...modulesSagas,
    ...recipeSaga,
    ...channelsSaga,
    ...simulationSaga,
  ] as Effect[];

  yield all(effects);
}
