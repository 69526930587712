import { ModuleBundleType } from 'types';
import { API } from './constants';
import { checkAndFormatAxiosError } from './error';
import { apiInstance, getAuthorizationHeader } from './index';

type Payload = {
  documentId: number;
  majorVersion: number;
  minorVersion: number;
};

export async function getModuleBundleChannels(
  sessionId: string,
  payload: Payload,
): Promise<ModuleBundleType> {
  try {
    const { documentId, majorVersion, minorVersion } = payload;
    const url = `${documentId}/${majorVersion}/${minorVersion}`;

    const { data } = await apiInstance.get<{ data: ModuleBundleType }>(
      `${API.GET_MODULE_BUNDLE_CHANNELS}/${url}`,
      { headers: getAuthorizationHeader(sessionId) },
    );

    return data.data;
  } catch (error) {
    throw checkAndFormatAxiosError(error);
  }
}
