export * from './ProjectsConfig';
export * from './ProjectType';
export * from './PreviewOptionName';

export const DEFAULT_SECTION_WIDTH = 600;

export enum ChannelType {
  EMAIL = 'Email',
  ALERT = 'Alert - Mobile/Brand',
  AD = 'Banner/Digital Ad',
}

export enum SAGA_STATUS {
  PENDING = 'pending',
  IDLE = 'idle',
}

export const DEFAULT_CLASSIFICATION = 'unclassified';

export const CLEAR_ZONE_BUTTON = 'Clear Zone';
