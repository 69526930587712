import { SAGA_STATUS } from 'const';
import { Layout, ModuleBundleDocument, ScreenToView } from 'types';

export enum SimulationScrollSide {
  RIGHT = 'right',
  LEFT = 'left',
}

export type SimulationState = {
  searchString: string;
  moduleBundleData: {
    moduleBundle: ModuleBundleDocument;
    status: SAGA_STATUS;
    error: string;
  };
  templateData: {
    template: ModuleBundleDocument;
    status: SAGA_STATUS;
    error: string;
  };
  mergedSimulationCombinations: ScreenToView[];
  activeScreenId: string;
  activePanels: Record<string, boolean>;
  templateReplacedLayouts: Record<string, Layout[]>;
  scrollPositions: Record<SimulationScrollSide, number>;
};

export type SimulationAddLayoutOnSectionPayload = {
  entityId: string;
  layout: Layout;
  shouldRemoveLayout?: boolean;
};

export type SimulationRemoveLayoutOnSectionPayload = {
  entityId: string;
};

