import { useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store/hooks';
import { actions } from 'store/modules/slice';
import { BundlesContext } from '../context/bundles';
import { ModulesBundleWithSelection } from './helpers';
import { ActionType } from './useBundles';

type Return = {
  bundle: ModulesBundleWithSelection | undefined;
  toggleAll: () => void;
  toggleModule: (moduleIndex: number) => void;
  toggleGroup: (groupIndex: number) => void;
  createGroup: (indexes: number[]) => void;
  removeGroup: (groupIndex: number) => void;
  shiftModuleInGroup: (groupIndex: number, moduleIndex: number, shift: number) => void;
};

export function useBundle(bundleIndex: number): Return {
  const { bundles, bundlesDispatch } = useContext(BundlesContext);
  const bundle = bundles[bundleIndex];

  const dispatch = useAppDispatch();

  const toggleAll = useCallback(
    () => bundlesDispatch({ type: ActionType.TOGGLE_ALL, bundleIndex }),
    [bundleIndex],
  );

  const toggleModule = useCallback(
    (moduleIndex: number) => bundlesDispatch({
      type: ActionType.TOGGLE_MODULE,
      bundleIndex,
      moduleIndex,
    }),
    [bundleIndex],
  );

  const toggleGroup = useCallback(
    (groupIndex: number) => bundlesDispatch({
      type: ActionType.TOGGLE_GROUP,
      bundleIndex,
      groupIndex,
    }),
    [bundleIndex],
  );

  const createGroup = useCallback((moduleIndexes: number[]) => {
    dispatch(actions.createGroup({ bundleIndex, moduleIndexes }));
    toast('Module Group Created Successfully', { hideProgressBar: true });
  }, [dispatch, bundleIndex]);

  const removeGroup = useCallback((groupIndex: number) => {
    dispatch(actions.removeGroup({ bundleIndex, groupIndex }));
    toast('Module Group Deleted', {
      hideProgressBar: true,
      style: { backgroundColor: '#6C757D' },
    });
  }, [dispatch, bundleIndex]);

  const shiftModuleInGroup = useCallback((groupIndex: number, moduleIndex: number, shift: number) => {
    dispatch(actions.shiftModuleInGroup({
      bundleIndex,
      groupIndex,
      moduleIndex,
      shift,
    }));
  }, [dispatch, bundleIndex]);

  return {
    bundle,
    toggleAll,
    toggleModule,
    toggleGroup,
    createGroup,
    removeGroup,
    shiftModuleInGroup,
  };
}
