import React from 'react';
import NoResults from 'assets/images/NoResults';

const NoContentFound: React.FC = () => (
  <tr>
    <td className="border-0" colSpan={3}>
      <div className="d-flex flex-column align-items-center my-5">
        <NoResults />
        <div className="mt-3 text-center">
          <p className="fw-bold">No content found</p>
          <p>
            Your filter set did not match any projects.
            <br />
            Please try again.
          </p>
        </div>
      </div>
    </td>
  </tr>
);

export default NoContentFound;
