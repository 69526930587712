import _ from 'lodash';
import { DEFAULT_CLASSIFICATION } from 'const';
import type { Layout, Screen, ScreenToView } from 'types';

export const filterModuleBundleLayouts = (screens: Screen[]): ScreenToView[] =>
  screens.map((screen: Screen) => {
    const allLayouts = _(screen.sections).flatMap('layouts').value();
    const filteredLayouts: Layout[] = allLayouts.filter((layout: Layout) =>
      screen.screenTypeDisplayName.toLocaleLowerCase().includes(layout.classification.toLocaleLowerCase()));

    const unclassifiedLayouts: Layout[] = filteredLayouts.length
      ? []
      : allLayouts.filter((layout: Layout) => layout.classification.toLocaleLowerCase() === DEFAULT_CLASSIFICATION);

    return {
      id: screen.id,
      name: screen.name,
      displayName: screen.screenTypeDisplayName,
      sectionIds: [],
      layouts: filteredLayouts.length ? filteredLayouts : unclassifiedLayouts,
    };
  });
