import React from 'react';

import styles from '../styles.module.scss';

const Disclaimer = (): React.JSX.Element => {
  return (
    <div className={styles.Disclaimer}>
      <p className={`${styles.DisclaimerTitle} mb-0`}>Disclaimer</p>
      <p className='mb-0'>
        The image in the screen is a simulation of how content modules might be grouped for the purposes of providing
        guidance and finalizing business rules. It is not a representation of the final tactic.
      </p>
    </div>
  );
};

export default Disclaimer;
