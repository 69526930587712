import React, { ForwardedRef, forwardRef, useId } from 'react';
import BiChevronDown from 'assets/images/bi-chevron-down.svg';
import BiChevronUp from 'assets/images/bi-chevron-up.svg';
import BiExclamationCircle from 'assets/images/bi-exclamation-circle.svg';
import BiLayersFill from 'assets/images/bi-layers-fill.svg';
import BiTrash from 'assets/images/bi-trash.svg';
import ClipboardText from 'components/Clipboard/ClipboardText';
import LayoutPreview from 'components/LayoutPreview';
import { ModulesBundleGroup } from 'store/modules/types';
import styles from '../Modules.module.scss';

type Props = {
  group: ModulesBundleGroup;
  onChange: () => void;
  onRemove: () => void;
  onShitfModule: (module:number, shift: number) => void;
  checked: boolean | undefined;
  showNumbers: boolean;
};

export const BundleListItemGroup = forwardRef<HTMLElement, Props>((props, ref) => {
  const {
    group,
    onChange,
    onRemove,
    onShitfModule,
    checked,
    showNumbers,
  } = props;
  const { modules } = group;
  const htmlId = useId();

  return (
    <label
      className={`mb-2 ${styles.bundlesListItem} border rounded`}
      htmlFor={htmlId}
      ref={ref as ForwardedRef<HTMLLabelElement>}
    >

      <label className="form-check-label title">
        <input
          className="form-check-input custom-check me-2"
          type="checkbox"
          value=""
          id={htmlId}
          onChange={onChange}
          checked={Boolean(checked)}
        />
        <BiLayersFill className='me-2 flex-shrink-0' />
        <ul className={`title-label ${styles.groupModulesList}`}>
          {modules.map((module, i) => {
            return (
              <li key={module.number} className={styles.groupModulesListItem}>
                <span className={styles.groupModulesListItemText}>Asset #{module.index + 1}</span>
                {showNumbers && (<ClipboardText text={module.number} />)}{modules.length - 1 !== i ? ' +' : '' }</li>
            );
          })}
        </ul>
        {group.hasDuplicates && (
          <div className="text-warning">
            <BiExclamationCircle className='me-2 mb-1' />
            Such module group has already been created
          </div>
        )}
        <div className="flex-grow-1" />
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={onRemove}
          type="button"
        >
          <BiTrash />
        </button>
      </label>

      <div className="content">
        {modules.map((module, idx) => {
          const upDisabled = idx <= 0;
          const downDisabled = idx + 1 >= modules.length;

          return (
            <div className="content-row" key={module.index}>
              <div className="content-controls">
                <div className="btn-group bg-white shadow-sm" role="group">
                  <button
                    type="button"
                    className="btn btn-sm btn-light custom-button"
                    disabled={downDisabled}
                    onClick={(event): void => {
                      event.preventDefault();
                      event.stopPropagation();
                      onShitfModule(module.index, 1);
                    }}
                  >
                    <BiChevronDown />
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-light custom-button"
                    disabled={upDisabled}
                    onClick={(event): void => {
                      event.preventDefault();
                      event.stopPropagation();
                      onShitfModule(module.index, -1);
                    }}
                  >
                    <BiChevronUp className="mb-1"/>
                  </button>
                </div>
              </div>

              <LayoutPreview
                documentId={module.id}
                alt={module.classification} />
            </div>
          );
        })}
      </div>
    </label>
  );
});

BundleListItemGroup.displayName = 'BundlesListItemGroup';
